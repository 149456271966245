
import { collection } from 'firebase/firestore';
import { getAnalytics } from "firebase/analytics";
import { firebaseHelper } from 'ui';
const useEmulator = false;
export const useStaging = !    (window.location.hostname.includes('mattoboard.com') || window.location.hostname.includes('preview'))
// export const useStaging=false
//export const useStaging=true
export const templateUserID = useStaging ? 'XlyuGVV9vxS068GswkXnajza1oq1' : 'HLVIWplaT4cqYhrx213kDIozdI93'
export const curatedTemplateUserID = useStaging ? '16WeGcOwD4dQc0G38NOjgp5TJoJ3' : 'py1hSLQqgbVBd59uYVMEcTLX4nI3'
export const curatedImagesUserId = useStaging ? 'pQsaHDNWVMMueRutIkN9jG7TQur1' : 'AoHdX1W5ZOgYgXh6S5OVnG5blGC3'
export const typesenseApiKey = useStaging ? 'Mb26vLBvxv5XfprfIq9MFxdGEHLWbUIw' : 'wlECOgKcFkuRUaWlNTSE01RA9q63aV3y'
export const typesenseHost = useStaging ? '5em0syz9xui4gra1p-1.a1.typesense.net' : 'vsgxbl8mn9k5o61up.a1.typesense.net'
export const seatableApi = useStaging ? 'ecadc4c192882af51ccbdb8857576e119622a202' : 'ecadc4c192882af51ccbdb8857576e119622a202';

export const proCuratedTemplateIDs= useStaging ? ['lw8nboi3cd'] : ['f7ytctigai','2qu7hyfr36','nbuq197xxl','xr15x99lb7','kjh8ixpo4p','8sc411nnqb','swf3hcak8j','3dlq7sgmd8','2lmiyl08hb','y2c8cqeoyp','1n3a3683gy','m20hc2rm1y','geq0ojfp9x','fz5ndfzqni','tlem2rp5sd','ppvoie6x6y','dde3fd7xiv','bceipdaneo'].sort()

export const proBlankTemplateIDs= useStaging ? [] : ['55r2ie75tg','bay8qf5qng','5kv9kupnea','6f8h19ivg2','9nnv6oz7nz','a1q1w49nft','ci0x3jluee','e26ycce7ra','fd49gsziz9','e6zlfg4ys1','gfc6jlg5nd','gr5ikxitvg','gt40xy5x2k','6n8m5k9719','msgfe76zd2','nl6mhb76uo','r2jaf16ezw','rx6zcbi9gp','s4bexqcz0c','su6ml0i7x4','tt9jkmp5cw','uhl9ixgzp1','vejhsxsj78','xapj9ikpfk','20qyhd5um0','bo8jppbm13','6zwwg2b1xg','8fvs8a4txk','bucd9qqs6e','18it6m7y6w','y9qy33jsgb','e0giefkuth','ic2pu5gqzd','qns6mne1rq','jkv4eh2b04','r9kdsoatke','1z48ghncrm'].sort()

export const MAX_FREE_RENDERS=3
export const MAX_FREE_UPLOADS=10
export const MAX_FREE_MATERIALS=10
export const MAX_FREE_PROJECTS=5

//['7bpk5ud1ri','85lz8f2n7h','2rnoz9dxn4','2t9w3fo94w','yhy2ppdjip','s9per0maly','tnvhdjsygi','z5ew3br6cm','qv65c1zlbc','8j4sf0tdix','yjc5konr3a','j257nwhgbe','sbsdwnpss9','tqybj12a7q','tu1xn17ty5','em1oftuldn','faf72k1ous','4333z7gh94','3jwbjw0guk','hmxvphyszv','o3x7pue7t1','1vzvqp8lzf','9axhx7fqit',
//'capbrdeeeh','iz9xtuaekd','776smeb3tt','1ups0ws1dv','2rs9o5613z', '3bw30ro1bi','4xg7637nwy', '5c0mnj91c9', '9rqb79u0n2', 'aqnfkuit37', 'jravnyf2uy','21f0zpy5ms','9ec55ixbob','em4mdii75a', 'irgx3pp81s', 'm5k0i5nxi1', 'nwof60i0bg', 'szbvu14pzr', 'u79lme4y1g','vafsqz7wxc', 'x2xsspm3a9', 'z0seqbbz6d'].sort()

const firebaseConfigProduction = {
  apiKey: "AIzaSyCTGdYrK9O7Vt2DpG3aqMi-9Kux3uKPEvA",
//authDomain: "mattoboard-b8284.firebaseapp.com",
  authDomain: "mattoboard.com",
  projectId: "mattoboard-b8284",
  storageBucket: "mattoboard-b8284.appspot.com",
  messagingSenderId: "709632310154",
  appId: "1:709632310154:web:ceeb0aa750d8c2d3e12673",
  measurementId: "G-0SZ3V9684H"
};
const firebaseConfigStaging = {
	apiKey: "AIzaSyAt-1lkI-PZaYeP4uQvLhYCJbWRU1DpzZU",
	// authDomain: "mattoboard-staging.firebaseapp.com",
	authDomain: "mattoboard-staging.web.app",
	projectId: "mattoboard-staging",
	storageBucket: "mattoboard-staging.appspot.com",
	messagingSenderId: "829457126264",
	appId: "1:829457126264:web:4593809c2b4fa145a46e8d",
	//measurementId: "${config.measurementId}"
};
export const firebaseConfig = useStaging ? firebaseConfigStaging : firebaseConfigProduction


/*
Adapters to the now shared library in ui + some legacy variables.
*/
export let googleSignup   = firebaseHelper.googleSignup
export let sendResetPassword = firebaseHelper.sendResetPassword
export let resetPassword = firebaseHelper.resetPassword
export let getDocByID = firebaseHelper.getDocByID
export let getUrlFromRef  = firebaseHelper.URLFromRef
export let getPublicURL = firebaseHelper.publicURL
export let updateRecord = firebaseHelper.updateRecord
export let uploadAndSave = firebaseHelper.uploadAndSave
export let uploadAndSave2 = firebaseHelper.uploadAndSave2

export let deleteRecordAndFiles = firebaseHelper.deleteRecordAndFiles
export let uploadFileToGCloud = firebaseHelper.uploadFileToGCloud
export let getProductCache = firebaseHelper.getProductCache

//export const storageURL = useStaging ? 'mattoboard-staging.appspot.com' : 'mattoboard-b8284.appspot.com'

// //export const firebaseApp = initializeApp(useStaging ? firebaseConfigStaging : firebaseConfig)
export let firebaseApp=null
export let analytics=null
export let projectsDB = null
export let productsDB = null
export let profilesDB = null
export let userImagesDB = null
export let userFavoritesDB = null
export let userCollectionsDB = null
export let userProductsDB = null
export let userProductFiltersDB = null
export let suppliersDB = null
export let notificationsDB = null
export let notificationsViewedDB = null
export let storageURL=null
export let deepRenderDB = null
export let firebaseDB = null

export const setupHelpers = () => {
	firebaseApp = firebaseHelper.firebaseApp
	const db = firebaseHelper.db	
	firebaseDB = db
	analytics = getAnalytics(firebaseApp);
	projectsDB = collection(db, "projects")
	productsDB = collection(db, "productsV2")	
	profilesDB = collection(db, "profiles")
	userImagesDB = collection(db,"userImages")
	userProductsDB = collection(db,"userProducts")
	userProductFiltersDB = collection(db,"userProductFilters")
	userFavoritesDB = collection(db,"userFavorites")
	userCollectionsDB = collection(db,"usersMyCollection")
	deepRenderDB = collection(db,"deepRender")
	suppliersDB = collection(db,"suppliers")
	notificationsDB = collection(db,"notifications")
	notificationsViewedDB = collection(db,"notifications_viewed")
	storageURL = firebaseApp.options.storageBucket
	googleSignup   = firebaseHelper.googleSignup.bind(firebaseHelper)
	sendResetPassword = firebaseHelper.sendResetPassword.bind(firebaseHelper)
	resetPassword = firebaseHelper.resetPassword.bind(firebaseHelper)
	getDocByID = firebaseHelper.getDocByID.bind(firebaseHelper)
	getUrlFromRef  = firebaseHelper.URLFromRef.bind(firebaseHelper)
	getPublicURL = firebaseHelper.publicURL.bind(firebaseHelper)
	updateRecord = firebaseHelper.updateRecord.bind(firebaseHelper)
	uploadAndSave = firebaseHelper.uploadAndSave.bind(firebaseHelper)
	uploadAndSave2 = firebaseHelper.uploadAndSave2.bind(firebaseHelper)
	deleteRecordAndFiles = firebaseHelper.deleteRecordAndFiles.bind(firebaseHelper)
	uploadFileToGCloud = firebaseHelper.uploadFileToGCloud.bind(firebaseHelper)
	getProductCache = firebaseHelper.getProductCache.bind(firebaseHelper)
}

