import React, { useEffect, useRef, useState} from 'react'
import { Box, Button, DialogContent, DialogActions, Dialog, DialogTitle, Card, CardContent, CardActions, Tooltip, Typography, IconButton, Grid } from '@mui/material'
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined'
import {ActionButton, BarIconButton} from './ButtonStyles'
import { useMattoState, usePhysicalObjects, useProjectData } from '../MattoState'
import { useLoginState } from "../studioxr/auth/LoginState";
import OpenInNewRoundedIcon from '@mui/icons-material/OpenInNewRounded';
import { getDocs, query, where } from 'firebase/firestore'
import { productsDB, userImagesDB, userProductsDB, getPublicURL, getUrlFromRef } from '../libs/firebase'
import { getMaterialThumbnail, cloudflareCDN, getProductThumbnail } from '../libs/util'
import { ContentEditor } from '../components/ContentEditor'
import { produce } from 'immer'
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';

export function MaterialSheetButton(props) {
    const saveProjectNow = useMattoState((state) => state.saveProjectNow);
    const [previewOpen, setPreviewOpen]=useState(false)
    const [saveProject, setSaveProject] = useState(false);
    const projectData = useProjectData()
    const setSnackbarMessage = useMattoState((state) => state.setSnackbarMessage);
    const setPdfProcessing = useMattoState((state) => state.setPdfProcessing);

    useEffect(() => {
        if (!previewOpen) {
            setPdfProcessing(false);  // Stop PDF processing when the dialog is closed
            setSnackbarMessage('');  // Clear any existing snackbar message
        }
    }, [previewOpen, setPdfProcessing]);

    const handleClose = () => {
        setPdfProcessing(false);
        setPreviewOpen(false);
        if(saveProject) {
            useMattoState.setState({ saveProjectNow:true });
            setSaveProject(false);
        }
    }

    const handleClick = () => {
        if(!projectData.projectId) {
            alert('Please save your project for downloading the material sheet');
        } else {
            if (window.gtag)  window.gtag('event','open_material_sheet'); 
            setPreviewOpen(true)
        }
    }
    
    return (
        <React.Fragment>
            <Tooltip title="Material Sheet" arrow>
                <BarIconButton
                    onClick={handleClick}
                    edge='start'
                    size="large"
                    aria-label="Material Sheet Button"
                    disabled={saveProjectNow===true ? true: false}>
                    <DescriptionOutlinedIcon style={saveProjectNow===true ? {color:'grey'}: {color:'black'}} />
                </BarIconButton>
            </Tooltip>
            {previewOpen && <PreviewDialog open={previewOpen} onClose={handleClose} setSaveProject={setSaveProject} /> }
        </React.Fragment>
    );
}

const PreviewDialog = ({open, onClose, setSaveProject}) => {
    const profile = useLoginState((state) => state.profile);
    const projectData = useProjectData()
	const physicalObjects = usePhysicalObjects( (state) => state.physicalObjects)
    const [materials, setMaterials] = useState([]);
    const [loading, setLoading] = useState(true);
    // const [swatchboxEnabled, setSwatchboxEnabled] = useState(false);
    const [physicalObjectMap, setPhysicalObjectMap] = useState({});
    const updatePhysicalObject = usePhysicalObjects(state=>state.updatePhysicalObject);
    const currentDate = new Date().toLocaleDateString('en-GB');
    const [projectScreenshot, setProjectScreenshot] = useState(null);
	const setProjectData = useProjectData(state=>state.set)
    const [updatingContent, setUpdatingContent] = useState(false);
    const [triggerDownload, setTriggerDownload] = useState(false);
    const pdfProcessing = useMattoState((state) => state.pdfProcessing);
    const setPdfProcessing = useMattoState((state) => state.setPdfProcessing);
    const setSnackbarMessage = useMattoState((state) => state.setSnackbarMessage);

    const paddingLeft = "10px";

    // useEffect(() => {
    //     if (profile?.country) {
    //         if (profile?.country === 'US' || profile?.country === 'United States' || profile?.country === 'Canada' || profile?.country === 'Mexico') setSwatchboxEnabled(true);
    //         else setSwatchboxEnabled(false);
    //     }
    // }, [profile]);

    const handleOpen = () => { }
    const handleClose = () => { onClose() };

    const updatePhysicalObjectDescription = (id, description) => {
        setUpdatingContent(true);
        if(physicalObjectMap[id]){
            const physicalObject = physicalObjectMap[id];
            const newObj = produce(physicalObject, (draft) => {
                if(draft?.dynamicMaterialProps)  draft.dynamicMaterialProps.description = description;
                else if(draft?.paintMaterialsData?.length > 0) { draft.paintMaterialsData.forEach(paintItem => { if(paintItem?.id == id) paintItem.description = description;}) }
                else if(draft?.materialData?.id) draft.materialData.description = description; 
                else if(draft?.type == "gltf" || draft.productType == "static") draft.user_description = description; 
            });
            updatePhysicalObject(newObj);
            setSaveProject(true);
        }
        else if(projectData?.backgroundTexture?.materialData?.id == id) {
            const newObj = produce(projectData.backgroundTexture, (draft) => {
                if(draft?.materialData) draft.materialData.description = description;
            });
            setProjectData(state=> { state.backgroundTexture = newObj })
            setSaveProject(true);
        } else {
            console.log('no object found for id:', id);
            setUpdatingContent(false);
        }
    }

    useEffect(() => {
        if(projectData.screenshot) {
            setProjectScreenshot(cloudflareCDN(getUrlFromRef(projectData.screenshot),'height=180,format=auto'));
        }
    }, [])

    const fetchProducts = async (db, productsArray) => {
        const chunkSize = 30;
        const chunks = [];
    
        // Split productsArray into chunks of 30 items
        for (let i = 0; i < productsArray.length; i += chunkSize) {
            chunks.push(productsArray.slice(i, i + chunkSize));
        }
    
        const results = [];
    
        // Perform multiple queries
        for (const chunk of chunks) {
            const q = query(db, where('id', 'in', chunk));
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                results.push(doc.data());
            });
        }
    
        return results;
    };

    useEffect(() => {
        const getMaterialData = async (productsArray, userImagesArray, userProductsArray, descriptionArray) => {
            const tempMaterials = [];
            if(productsArray.length > 0) {
                const products = await fetchProducts(productsDB, productsArray);
                products.forEach(product => {
                    const id = product.id;
                    let name = product.name;
                    const supplier = product.supplier;
                    const description = descriptionArray[product.id] ?  descriptionArray[product.id] : (product?.user_description?.length > 3) ? product?.user_description : '';
                    const thumbnail = product.isStaticObject && (product.type == 'gltf' || product.productType == 'static') ? getProductThumbnail(product) : getMaterialThumbnail(product);
                    const productLink = product.productLink || '';
                    // let swatchboxProductID = null, swatchboxOptionID = null, swatchboxSampleID = null; 
                    let sku = null;
                    // if(product.swatchboxProductID) {
                    //     swatchboxProductID = product.swatchboxProductID;
                    //     swatchboxOptionID = product.swatchboxOptionID;
                    //     swatchboxSampleID = product.swatchboxSampleID;
                    //     sku = product.sku;
                    //     // remove sku from name if exists
                    //     name = name.replace(sku, '');
                    // }
                    tempMaterials.push({
                        id:id,
                        name: name,
                        supplier: supplier,
                        description: description,
                        thumbnail: thumbnail,
                        // swatchboxProductID: swatchboxProductID,
                        // swatchboxOptionID: swatchboxOptionID,
                        // swatchboxSampleID: swatchboxSampleID,
                        sku: sku,
                        productLink: productLink
                    });
                });    
            }

            if(userImagesArray.length > 0) {
                const userImages = await fetchProducts(userImagesDB, userImagesArray);
                userImages.forEach(userImage => {
                    const id = userImage.id;
                    const name = userImage.name;
                    const description = descriptionArray[userImage.id] ?  descriptionArray[userImage.id] : userImage?.description;
                    const imageFile = userImage.files.file;
                    let storageUrl = getPublicURL(imageFile);
                    const thumbnail = cloudflareCDN(storageUrl, 'height=100,format=auto') 
    
                    tempMaterials.push({
                        id:id,
                        name: name,
                        supplier: 'User Created',
                        description: description,
                        thumbnail: thumbnail,
                        productLink: ''
                    });
                });
            }

            if(userProductsArray.length > 0) {
                const userProducts = await fetchProducts(userProductsDB, userProductsArray);
                userProducts.forEach(userProduct => {
                    const id = userProduct.id;
                    const name = userProduct.name;
                    const description = descriptionArray[userProduct.id] ?  descriptionArray[userProduct.id] : userProduct?.metadata?.description;
                    const supplier = userProduct?.metadata?.brand;
                    const productLink = userProduct?.metadata?.url;
                    const colorFile = userProduct.materialData.files.color_original;
                    let thumbnail = null;
                    if (colorFile.startsWith('data:image'))  thumbnail = colorFile; 
                    else thumbnail = cloudflareCDN(colorFile,'height=100,format=auto');
    
                    tempMaterials.push({
                        id:id,
                        name: name,
                        supplier: supplier,
                        description: description,
                        thumbnail: thumbnail,
                        productLink: productLink
                    });
                });    
            }

            // remove duplicates from tempMaterials and set to materials
            const uniqueMaterials = tempMaterials.filter((v, i, a) => a.findIndex(t => (t.name === v.name)) === i);
            setMaterials(uniqueMaterials);
            setLoading(false);
            setUpdatingContent(false);
        }

        const createMaterialSheet = () => {

            if(physicalObjects.length === 0) {
                setLoading(false);
                setUpdatingContent(false);
                return;
            }

            const productsV2Array = []
            const userProductsArray = []
            const userImagesArray = []
            const descriptionArray = {}
            const physicalObjectArray = {}

            if(projectData?.backgroundTexture?.materialData?.id) {
                productsV2Array.push(projectData?.backgroundTexture?.materialData?.id); 
                if(projectData?.backgroundTexture?.materialData?.description) descriptionArray[projectData?.backgroundTexture?.materialData?.id] = projectData?.backgroundTexture?.materialData?.description; 
            }

            physicalObjects.forEach( physicalObj => {
                if(physicalObj?.isStaticObject && (physicalObj?.type == 'gltf' || physicalObj?.productType == 'static')) {
                    productsV2Array.push(physicalObj.meshId);
                    physicalObjectArray[physicalObj.meshId] = physicalObj;
                    if(physicalObj.user_description && physicalObj.user_description.length > 3) descriptionArray[physicalObj.meshId] = physicalObj.user_description;
                }
                else if((physicalObj?.materialData?.id && physicalObj?.materialData?.uid) || physicalObj.objectStatus == 'USER_CREATED') { 

                        userProductsArray.push(physicalObj?.materialData?.id); 
                        physicalObjectArray[physicalObj?.materialData?.id] = physicalObj; 
                        if(physicalObj?.materialData?.description) descriptionArray[physicalObj?.materialData?.id] = physicalObj?.materialData?.description; 
                }
                else if(physicalObj?.materialData?.id) { 

                    productsV2Array.push(physicalObj?.materialData?.id); 
                    physicalObjectArray[physicalObj?.materialData?.id] = physicalObj; 
                    if(physicalObj?.materialData?.description) descriptionArray[physicalObj?.materialData?.id] = physicalObj?.materialData?.description; 

                }
                else if(physicalObj?.paintMaterialsData?.length > 0) {

                    physicalObj.paintMaterialsData.forEach(paintItem => {
                        if(paintItem?.id && paintItem?.userCreated) userProductsArray.push(paintItem.id)
                        else if(paintItem?.id) productsV2Array.push(paintItem.id)

                        physicalObjectArray[paintItem.id] = physicalObj;
                        if(paintItem?.description) descriptionArray[paintItem?.id] = paintItem?.description;
                    })
                }
                else if(physicalObj?.dynamicMaterialProps) {
                    const originalFile = physicalObj?.dynamicMaterialProps?.originalFile || physicalObj?.dynamicMaterialProps?.files;
                    let segments = originalFile.split('/');
                    const id =  segments[ segments.length -2];
                    userImagesArray.push(id)

                    physicalObjectArray[id] = physicalObj;

                    if(physicalObj?.dynamicMaterialProps?.description) descriptionArray[id] = physicalObj?.dynamicMaterialProps?.description;
                }
            })
            setPhysicalObjectMap(physicalObjectArray);
            getMaterialData(productsV2Array, userImagesArray, userProductsArray, descriptionArray);
        }

        createMaterialSheet();
    }, [physicalObjects, projectData]);

    const ConditionalTooltip = ({ condition, title, children }) => {
        return condition ? (
          <Tooltip title={title} arrow>
            {children}
          </Tooltip>
        ) : (
          children
        );
    };

    useEffect(() => {
        const downloadIfNeeded = async () => {
          if (triggerDownload && !updatingContent) {
            try {
              if (window.gtag)  window.gtag('event','download_material_sheet_pdf') 
              await loadJsPDF();
              await loadDOMPurify();
              await loadHtml2Canvas();
              await loadHtml2Pdf();
              await generatePDF();
            } catch (error) {
              console.error('Error:', error);
            } finally {
                setPdfProcessing(false);
                setTriggerDownload(false);  // Reset the download trigger
                setSnackbarMessage('');  // Clear any existing snackbar message
            }
          }
        };
        downloadIfNeeded();
    }, [triggerDownload, updatingContent]);

    const handleDownload = async () => {
        if(!pdfProcessing) {
            setPdfProcessing(true);
            setTriggerDownload(true);
            setSnackbarMessage("One moment: Creating Material Sheet...", false);
        }
    };  
    
    const loadScript = (src) => {
        return new Promise((resolve, reject) => {
          const script = document.createElement('script');
          script.src = src;
          script.onload = () => resolve();
          script.onerror = () => reject(new Error(`Failed to load script ${src}`));
          document.head.appendChild(script);
        });
    };

    const loadJsPDF = () => loadScript('https://cdnjs.cloudflare.com/ajax/libs/jspdf/2.3.1/jspdf.umd.min.js');
    const loadDOMPurify = () => loadScript('https://cdnjs.cloudflare.com/ajax/libs/dompurify/2.3.1/purify.min.js');
    const loadHtml2Canvas = () => loadScript('https://cdnjs.cloudflare.com/ajax/libs/html2canvas/1.4.1/html2canvas.min.js');
    const loadHtml2Pdf = () => loadScript('https://cdnjs.cloudflare.com/ajax/libs/html2pdf.js/0.9.2/html2pdf.bundle.min.js');

    const generatePDF = async () => {
        const { jsPDF } = window.jspdf;
        let pdf = new jsPDF('p', 'pt', 'letter');
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();
        const xOffset = 32;
        const yOffset = 32;
        const contentHeightPerPage = pdfHeight - 2 * yOffset;
        let extraSpacing = 0;
    
        let currentPage = 1;
        let currentY = yOffset;

        const addFooter = (pageNumber) => {
            const footerY = pdfHeight - yOffset; // Y position of the footer
            pdf.setFontSize(10);
            pdf.text(`${pageNumber}`, pdfWidth - xOffset, footerY + 10); // Add page number
        };
    
        const addHeader = () => {
            pdf.setFontSize(16);
            pdf.text(projectData.title, xOffset, currentY); // Add the title at the top
        };

        const getImageAspectRatio = async (image) => {
            try {
                const img = new Image();
                img.src = image.split('&format=auto')[0];
        
                return new Promise((resolve, reject) => {
                    img.onload = () => {
                        const originalWidth = img.width;
                        const originalHeight = img.height;
                        const aspectRatio = originalWidth / originalHeight;
                        resolve(aspectRatio);
                    };
        
                    img.onerror = (error) => {
                        console.error('Error loading image:', error);
                        reject(error);
                    };
                });
            } catch (error) {
                console.error('Error loading image:', error);
                return 1;
            }
        };
        

        const addProjectScreenshot = async () => {
            if (projectScreenshot) {
                const aspect = await getImageAspectRatio(projectScreenshot);
                const projectScreenshotImg = new Image();
                projectScreenshotImg.src = projectScreenshot.split('&')[0];
                const imageWidth = aspect * 180;
                projectScreenshotImg.src = projectScreenshotImg.src + '&width=' + (imageWidth*2) + '&height=360';
                pdf.addImage(projectScreenshotImg, 'JPEG', pdfWidth - xOffset - imageWidth, currentY, imageWidth, 180);
                currentY += 220;
            }
        };

        const addDate = () => {
            pdf.setFontSize(8);
            pdf.setTextColor(107, 107, 107);
            pdf.text("DATE CREATED", pdfWidth - xOffset - 62, currentY - 8); // Add the date label
            pdf.setFontSize(10);
            pdf.setTextColor(0, 0, 0);
            pdf.text(currentDate, pdfWidth - xOffset - 50, currentY + 5); // Add the date below the title
        }

        const addSubHeader = (text, x, y) => {
            pdf.setTextColor(107, 107, 107);
            pdf.setFontSize(9);
            pdf.text(text, x, y);
            pdf.setTextColor(0, 0, 0);
        }

        const addText = (text, width, x, y) => {
            pdf.setFontSize(11);
            const splitText = pdf.splitTextToSize(text, width);
            pdf.text(splitText, x, y);
            if(splitText.length > 4) {
                extraSpacing = 10 * (splitText.length - 4);
            }
        }

        const addSpacingLine = () => {
            pdf.setDrawColor(211, 211, 211);
            pdf.setLineWidth(0.1);
            currentY += 15;
            pdf.line(xOffset, currentY, pdfWidth - xOffset, currentY);
            currentY += 15;
            pdf.setTextColor(0, 0, 0);
        }

        const addMaterialContent = async (material) => {
            const maxImageWidth = 75;  // Maximum allowed width
            const maxImageHeight = 75; // Maximum allowed height
            const textXOffset = xOffset + maxImageWidth + 10;
            extraSpacing = 0;
            let imageWidth = 0, imageHeight = 0;

            // Thumbnail Image
            if (material.thumbnail) {
                try {
                    const aspect = await getImageAspectRatio(material.thumbnail);
                    if (aspect > 1) {
                        // Image is wider than it is tall
                        imageWidth = maxImageWidth;
                        imageHeight = maxImageWidth / aspect;
                    } else {
                        // Image is taller than it is wide or square
                        imageHeight = maxImageHeight;
                        imageWidth = maxImageHeight * aspect;
                    }
                    const img = new Image();
                    img.src = material.thumbnail.split('&format=auto')[0];
                    img.src = img.src + '&width=' + (imageWidth * 2) + '&height=' + (imageHeight * 2);

                    const centeredXOffset = xOffset + (maxImageWidth - imageWidth) / 2;
                    const centeredYOffset = currentY + (maxImageHeight - imageHeight) / 2;

                    pdf.addImage(img, 'JPEG', centeredXOffset, centeredYOffset, imageWidth, imageHeight);

                } catch (error) {
                    console.error('Error loading image:', error);
                }
            }
    
            pdf.setFontSize(10);
            pdf.setTextColor(0, 0, 0);
    
            // Product
            addSubHeader('PRODUCT', textXOffset, currentY + 20);
            addText(material.name, 140, textXOffset, currentY + 35);
    
            // Brand
            addSubHeader('BRAND', textXOffset + 150, currentY + 20);
            if(material.supplier && material.supplier.length > 0) addText(material.supplier, 90, textXOffset + 150, currentY + 35);
    
            // console.log('material', material);
            // Description
            addSubHeader('DESCRIPTION', textXOffset + 250, currentY + 20);
            if(material.description && material.description.length > 0) addText(material.description, 135, textXOffset + 250, currentY + 35);
    
            // Product Link
            addSubHeader('PRODUCT LINK', textXOffset + 395, currentY + 20);
            if (material.productLink) {
                pdf.setFontSize(11);
                pdf.setTextColor(130, 183, 224);
                pdf.textWithLink('Click Here', textXOffset + 395, currentY + 35, { url: material.productLink });
                pdf.setTextColor(0, 0, 0);
            }
    
            currentY += Math.max(maxImageHeight, 75 + extraSpacing);
            addSpacingLine();
        };
    
        const addMaterialSection = async () => {
            for (const material of materials) {
                if(!useMattoState.getState().pdfProcessing) break; 
                if (currentY + 50 > contentHeightPerPage) { // Adjust height condition as needed
                    pdf.addPage();
                    currentPage += 1;
                    currentY = yOffset;
                    addFooter(currentPage);
                }
                await addMaterialContent(material);
            }
        };
    
        await addProjectScreenshot();
        addHeader();
        addDate();
        addSpacingLine();
        addFooter(currentPage);
        await addMaterialSection();

        if(useMattoState.getState().pdfProcessing) {
            const sheetName = projectData.title.replace(/[^a-zA-Z0-9]/g, '_') + '_Material_Sheet' + new Date().toISOString().split('T')[0] + '.pdf';
            pdf.save(sheetName);
        } 
        pdf = null;
        return;
    }

    const openProductLink = (id, link) => {
        console.log('Opening product id:', id, ' link:', link);
        if(window.gtag) {
            window.gtag('event', 'click', {
                'event_category': 'Material Sheet',
                'event_label': 'Product Link',
                'id': id,
                'value': link
            });
        }
        window.open(link, '_blank');
    }

    const handleEditDescriptionClick = (id) => {
        const material = materials.find(m => m.id === id);
        if (material && material.triggerEdit) {
            material.triggerEdit();
        }
    }

	return (
        <React.Fragment>
        <Dialog
            onClose={handleClose}
            disableEscapeKeyDown={true}
            aria-labelledby="simple-dialog-title"
            open={open}
            TransitionProps={{
                onEnter: handleOpen
            }}
            fullWidth={true}
            maxWidth='md'
        >
            <DialogContent sx={{maxHeight:"100%", overflow:"hidden"}}>
                <Grid container direction="row">
                    <Grid item xs={6} sx={{display: 'flex', flexDirection: 'column', alignItems: 'left'}}>
                        <Typography sx={{ fontSize: "18px", fontWeight: 'bold' }}>{projectData.title}</Typography>
                        <Typography sx={{ color: '#6B6B6B', fontSize: "12px" }}>Material Sheet</Typography>
                    </Grid>
                    {materials.length > 0 &&
                        <Grid item xs={6} sx={{display: 'flex', justifyContent: 'end'}}>
                            <ActionButton sx={{ width: '220px' }} disabled={pdfProcessing} onClick={() => handleDownload()}>Download Material Sheet</ActionButton>
                        </Grid>
                    }
                </Grid>
                {loading &&
                <Grid container direction="row" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '300px' }}>
                    <Typography sx={{ fontSize: '18px', fontWeight: 'bold' }}>Loading Materials</Typography>
                    <Typography sx={{ color: '#6B6B6B', fontSize: '12px' }}>Please wait...</Typography>
                </Grid>
                }
                {(materials.length > 0 && !loading) &&
                <Grid sx={{maxHeight: '60vh', overflowY: 'scroll' }}>
                    <Grid container sx={{ display: 'flex', flexDirection: 'column'}}>
                        <Grid sx={{ marginTop: '20px', borderTop: '1px solid #D3D3D3' }} />
                        {materials.map((material, index) => (
                            <Grid key={index} container direction="row" sx={{ borderBottom: '1px solid #D3D3D3', paddingTop: '20px', paddingBottom: '20px', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                                <Grid item xs={5} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    <Box
                                        sx={{ width: 100, height: "fit-content", borderRadius: '10px'}}
                                        component="img"
                                        src={material.thumbnail}
                                        alt="placeholder"
                                    />
                                    <Grid sx={{ display: 'flex', flexDirection: 'column', marginLeft: '10px', marginTop: '10px' }}>
                                        <Typography sx={{ textTransform: 'uppercase', fontSize: '0.65rem' }}>Product</Typography>
                                        <Typography sx={{ fontSize: '0.90rem', fontWeight: 'bold', whiteSpace: 'pre-wrap', overflowWrap: 'break-word', wordBreak: 'break-word' }}>{material.name}</Typography>
                                        {material.sku && <Typography sx={{ fontSize: '0.85rem' }}>{material.sku}</Typography>}
                                        {material.productLink && 
                                            <OpenInNewRoundedIcon sx={{ width: '18px', cursor: 'pointer', color: '#6b6b6b' }} onClick={() => openProductLink(material.id, material.productLink)} />
                                        }
                                    </Grid>
                                </Grid>
                                <Grid item xs={2} sx={{ display: 'flex', flexDirection: 'row', paddingLeft: paddingLeft }}>
                                    <Grid sx={{ display: 'flex', flexDirection: 'column', marginLeft: '10px', marginTop: '10px' }}>
                                        <Typography sx={{ textTransform: 'uppercase', color: '#6b6b6b', fontSize: '0.65rem' }}>Brand</Typography>
                                        <Typography sx={{ fontSize: '0.90rem', fontWeight: 'bold' }}>{material.supplier}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid item xs={3} sx={{ display: 'flex', flexDirection: 'row', paddingLeft: paddingLeft }}>
                                    <Grid sx={{ display: 'flex', flexDirection: 'column', marginLeft: '10px', marginTop: '10px' }}>
                                        <Grid sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                            <Typography sx={{ textTransform: 'uppercase', color: '#6b6b6b', fontSize: '0.65rem' }}>Description</Typography>
                                            <Tooltip title="Edit Description" arrow>
                                                <BarIconButton
                                                    onClick={() => handleEditDescriptionClick(material.id)}
                                                    sx={{ padding: '0px', paddingRight: '2px', paddingLeft: '5px' }}
                                                    edge='start'
                                                    size="small">
                                                    <CreateOutlinedIcon sx={{ width: '16px', height: '16px' }} />
                                                </BarIconButton>	
                                            </Tooltip>
                                        </Grid>
                                        <Grid sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                            <ContentEditor 
                                                id={material.id} 
                                                content={material.description} 
                                                setContent={updatePhysicalObjectDescription} 
                                                triggerEdit={(callback) => (material.triggerEdit = callback)}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={2} sx={{ display: 'flex', flexDirection: 'row', paddingRight: '4px', paddingLeft: paddingLeft }}>
                                    <Grid sx={{ display: 'flex', flexDirection: 'column', marginTop: '10px', alignItems: 'center' }}>
                                    {/* {material.swatchboxProductID && 
                                    <>
                                        <ConditionalTooltip condition={!swatchboxEnabled} title="This product is not available in your region.">
                                            <Box component="span">
                                                <Button 
                                                    onClick={()=>{}} 
                                                    variant="outlined"
                                                    disabled = {!swatchboxEnabled}
                                                    data-swatchbox-cart=""
                                                    data-swatchbox-product-id={material.swatchboxProductID}
                                                    data-swatchbox-option-id={material.swatchboxOptionID}
                                                    data-swatchbox-sample-id={material.swatchboxSampleID}
                                                    sx={{
                                                        background: 'white',
                                                        borderColor: (theme) => theme.palette.primary.shades[250],
                                                        '&:hover': { background: (theme) => theme.palette.primary.shades[100], borderColor: (theme) => theme.palette.primary.shades[250], color: (theme) => theme.palette.primary.light }
                                                    }}
                                                >
                                                    Order Sample
                                                </Button>
                                            </Box>
                                        </ConditionalTooltip>

                                        <Box
                                            sx={{ maxWidth: '100px', marginTop: '10px', width: '100%', height: '100%', objectFit: 'contain', 
                                                    opacity: swatchboxEnabled ? 1 : 0.2}}
                                            component="img"
                                            src={'/images/swatchbox_logo.webp'}
                                        />
                                    </>
                                    } */}
                                    {material.productLink &&
                                        <Button 
                                            onClick={() => openProductLink(material.id, material.productLink)} 
                                            variant="outlined"
                                            sx={{
                                                background: 'white',
                                                borderColor: (theme) => theme.palette.primary.shades[250],
                                                '&:hover': { background: (theme) => theme.palette.primary.shades[100], borderColor: (theme) => theme.palette.primary.shades[250], color: (theme) => theme.palette.primary.light }
                                            }}
                                            >
                                                Product Link
                                        </Button>
                                    }
                                    </Grid>
                                </Grid>
                            </Grid>                            
                        ))}
                    </Grid>
                </Grid>
                }
                {materials.length === 0 && !loading &&
                <Grid container direction="row" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '300px' }}>
                    <Typography sx={{ fontSize: '18px', fontWeight: 'bold' }}>No Materials Found</Typography>
                    <Typography sx={{ color: '#6B6B6B', fontSize: '12px' }}>Please add materials to your project</Typography>
                </Grid>
                }
            </DialogContent>    
            <DialogActions>
                <Button 
                    onClick={handleClose} 
                    variant="outlined"
                    sx={{
                        background: 'white',
                        borderColor: (theme) => theme.palette.primary.shades[250],
                        '&:hover': { background: (theme) => theme.palette.primary.shades[100], borderColor: (theme) => theme.palette.primary.shades[250], color: (theme) => theme.palette.primary.light }
                    }}
                >
                    Close
                </Button>
            </DialogActions>

        </Dialog>    
        </React.Fragment>
    );
}