import React, { useEffect, useState } from 'react'
import { Button, Checkbox, Divider, Grid, IconButton, Typography} from '@mui/material'
import RemoveIcon from '@mui/icons-material/Remove';
import { Add } from '@mui/icons-material';
import { useRefinementList } from 'react-instantsearch';
import { gtagFacetSearchEvent } from '../libs/util';

interface RefinementProps {
    attribute: string;
    attributeName?: string;
    limit?: number;
    showMore?: boolean;
    disabled?: boolean;
}
export const RefinementDropdown = ({attribute,attributeName='Color',limit,showMore,disabled}:RefinementProps) => {
    const [showAll, setShowAll]=useState(!showMore)
    const [open, setOpen] = useState(showMore);
    const handleClick = () => {  setOpen(!open);  }
    useEffect(() => { if(disabled) setOpen(false) }, [disabled])

    const { items,refine,canRefine} = 
        useRefinementList({ showMoreLimit:1000, attribute: attribute,limit: limit,showMore: showMore,sortBy: ['name:asc'] });

    useEffect(() => {
        if (attribute=='supplier') {
            const urlParams = new URLSearchParams(window.location.search);
            const defaultSupplier = urlParams.get('defaultSupplier')
            if (defaultSupplier && defaultSupplier?.length > 0 && canRefine)  {
                refine(defaultSupplier)
            }
        }
    },[canRefine])

    const refinedItems = items.filter(item => item.isRefined);
    const notRefinedItems = items.filter(item => !item.isRefined);

    return (
        <Grid container alignItems="center"  >    
            <Grid item xs={10}>
                <Typography style={disabled ? {color:'#cecece'}: {color:'black'}}>{attributeName}</Typography>
            </Grid>
            <Grid item xs={2}  >
                <IconButton onClick={handleClick} size="small" disabled={disabled}> {open ? <RemoveIcon /> : <Add /> } </IconButton>
            </Grid>
            {open==true &&
                <>
                <Grid>                  
                {refinedItems.map( (item,index) =>  {   
                    if (item.label.trim().length == 0) return;
                    return (                
                        <Grid item xs={12} key={index} style={{height:'29px', whiteSpace: 'nowrap', overflow: 'hidden'}}>
                            <Checkbox size='medium' style={{padding: '7px'}} checked={item.isRefined} onChange={() => { refine(item.value);if(!item.isRefined) gtagFacetSearchEvent(attribute,item.value); }} />
                            <Typography variant='caption' style={disabled ? {color:'#cecece'}: {color:'black'}}>{item.label} ({item.count})</Typography>
                        </Grid>
                    )
                }
                )}
                </Grid>
                <Grid item xs={12}>
                    {refinedItems.length > 0 && notRefinedItems.length > 0 && <Divider style={{paddingTop:'9px', borderColor: 'rgba(0, 0, 0, 0.5)'}}  />}
                </Grid>
                </>    
            }

            <Grid item xs={12} sx={!showMore?{maxHeight:'calc(74.5vh - 270px)',  overflowX:'clip', overflowY:'auto'}:{}}>
                {open==true && 
                <>
                    <Grid sx={{marginTop: '0px'}}>
                    {notRefinedItems.map( (item,index) =>  {   
                        if (item.label.trim().length == 0) return;
                        return (                
                            <Grid item xs={12} key={index} style={{height:'29px', whiteSpace: 'nowrap', overflow: 'hidden'}}>
                                <Checkbox size='medium' style={{padding: '7px'}} checked={item.isRefined} onChange={() => { refine(item.value);if(!item.isRefined) gtagFacetSearchEvent(attribute,item.value); }} />
                                <Typography variant='caption' style={disabled ? {color:'#cecece'}: {color:'black'}}>{item.label} ({item.count})</Typography>
                            </Grid>
                        )
                    }
                    )}
                    </Grid>
                    {showMore==true && items.length > 40 &&
                        <Button style={{paddingTop:'10px'}} size='small' disabled={disabled}  onClick={ () => setShowAll(!showAll)}> Show {showAll==true ? 'Less' : 'More' }</Button>
                    }            
                </>
                }
            </Grid>
            <Grid item xs={12}>
                <Divider style={{paddingTop:'9px'}}  />
            </Grid>
        </Grid>
    )
}


