import React, { useState, useEffect, useRef } from 'react';
import ContentEditable from 'react-contenteditable';
import { Grid } from '@mui/material';

export function ContentEditor(props) {
  const { id, content, setContent, triggerEdit } = props;
  const [html, setHtml] = useState(content || 'Add description');
  const [isHovering, setIsHovering] = useState(false);
  const contentEditable = useRef(null);
  const placeholderText = 'Add description'
  const isPlaceholder = html === placeholderText;
  const maxChars = 100;

  useEffect(() => {
    if (triggerEdit) {
        triggerEdit(() => handleIconClick());
    }
  }, [triggerEdit]);

  const handleChange = (evt) => {
    const v = evt.currentTarget.innerText.replace('>', '');
    if (v.length <= maxChars && v !== placeholderText) {
      setHtml(v);
    }
  };

  const handleBlur = (evt) => {
    const contentCurrent = evt.target.innerText.substring(0, maxChars);
    if (contentCurrent.length < 1) {
      if(content !== contentCurrent) setContent(id, '');
      setHtml(placeholderText);
    } else {
      if(content !== contentCurrent) setContent(id, contentCurrent);
      document.activeElement.blur();
    }
  };

  const keyDown = (evt) => {
    if (evt.code === 'Enter') evt.target.blur();
  };

  const handleFocus = () => {
    if (isPlaceholder) setHtml('');
  };

  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  const handleIconClick = () => {
    setIsHovering(true);
    contentEditable.current.focus();
  };

  return (
    <Grid sx={{ display: 'flex', flexDirection: 'row' }}>
      <ContentEditable
        style={{
          display: isHovering ? '' : 'inline',
          cursor: isHovering ? 'pointer' : 'auto',
          border: isHovering ? '1px solid #000' : 'none',
          whiteSpace: 'pre-wrap',
          overflowWrap: 'break-word', 
          wordBreak: 'break-word',
          width: '100%', // Ensures the content fits within the parent container
          color: isPlaceholder ? '#ccc' : '#000', // Placeholder color
        }}
        innerRef={contentEditable}
        html={html}
        disabled={false} // use true to disable editing
        onChange={handleChange} // handle innerHTML change
        onBlur={handleBlur}
        onFocus={handleFocus} // handle focus event to clear placeholder
        onKeyDown={keyDown}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      />
    </Grid>
    
  );
}
