
import { useStaging } from "../libs/firebase.js";
import { formatPrice } from "../libs/util.ts";

export const stripeProcessorURL = useStaging ? 'https://us-central1-mattoboard-staging.cloudfunctions.net/StripeProcessor5' : 'https://us-east1-mattoboard-b8284.cloudfunctions.net/StripeProcessor5';

export const cancelStripeSubscriptionAPIUrl = stripeProcessorURL + '/cancelStripeSubscription';
export const getStripeSetupIntentAPIUrl = stripeProcessorURL + '/createPaymentIntent';
export const getStripePaymentIntentAPIUrl = stripeProcessorURL + '/createImmediatePaymentIntent';
export const getStripeInvoicesAPIUrl  = stripeProcessorURL + '/getStripeInvoice';
export const createStripeSubscriptionAPIUrlV3 =   stripeProcessorURL + '/createSubscriptionV3';
export const createStripeSubscriptionAPIUrlV4 =   stripeProcessorURL + '/createSubscriptionV4';
export const getSubscriptionAPIUrl  = stripeProcessorURL + '/getSubscription';
export const revertStripeSubscriptionAPIUrl = stripeProcessorURL + '/revertStripeSubscription';
export const getPricesAndLocationsAPIUrl = stripeProcessorURL + '/getPricesAndLocation';

export const getPriceURL = stripeProcessorURL + '/getPrices';
export const publishableKey = useStaging ?
 'pk_test_51M4werAMgAm8AkfpJ203wXJrgT22dTeadRv8LubM0CvnEgBJEe9KTbOfgOBbzHYQczwrBgXu7GMGSo4RSJFZcNm800ythAObmp' :'pk_live_51M4werAMgAm8Akfp1Yc4LmgpwqPQ8KzMPfOccVakXkZO836iy00WqzNkuaLacHXfNywtpxOj3LDpv1zhhMoZaia0000UhJjiED';

export  const stripePitchImage = 'stripe_images/expert-pitch-spread--HALF-IMAGE-1.jpg'


export const getGoogleAnalyticsProductInfo = (profilePrices) => {
    const price = formatPrice(profilePrices.local_price, profilePrices.currency, true,'decimal')
    return  {  currency: profilePrices.currency, value: price,
            items: [ { id: profilePrices.priceID,name: "Mattoboard Pro Subscription",category: "subscription",
                quantity: 1,price:price   } ]            
      }
}