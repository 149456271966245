import React, { useRef,useState,useEffect } from 'react'
import { Canvas,useThree,extend } from '@react-three/fiber'
import {usePhysicalObjects,useMattoState} from '../MattoState'
import useEventListener from '../libs/useEventListener';
import {hashCode, positionIn3D, resizeImage, uploadImage , getCanvas, checkFileType, checkWebsiteFileType, cleanGLTFMesh, materialObjectType, capitalize} from '../libs/util'
import { planePhysicalObject } from './objects/DynamicObject';
import {  CanvasObject, DynamicMaterialProps, PhysicalObjectInterface } from '../ts/app_interfaces';
import { getUrlFromRef, uploadAndSave, getDocByID, getPublicURL} from '../libs/firebase';
import { produce } from 'immer';
import { generateID } from 'ui';
import { ProductType } from 'ts-interfaces';
import { useStaging } from '../libs/firebase';

export const DropPhysicalObject = (props) => {
	const currentUser = props.currentUser;
	const { camera, gl, invalidate,raycaster } = useThree()
	const addPhysicalObject = usePhysicalObjects((state) => state.addPhysicalObject)
    const getSelectedPhysicalObject = usePhysicalObjects( (state) => state.getSelectedPhysicalObject)
    const updatePhysicalObject = usePhysicalObjects(state=>state.updatePhysicalObject)
	const onClick = () => { 
		if (document.activeElement instanceof HTMLElement) {
			document.activeElement.blur(); 
		}
	}
	const onDragOver = (e) => {  e.preventDefault() }
	const onDrop = (e) => {
		e.preventDefault()
		const isMesh = e.dataTransfer?.getData('mesh')
		const isMaterial = e.dataTransfer?.getData('material')
		const isPaint = e.dataTransfer?.getData('paint')
		const isWebsiteImage = e.dataTransfer.types.includes('text/html') && !isMesh && !isMaterial && !isPaint? true : false

		if (e.dataTransfer.types.length > 0 && (
			(e.dataTransfer.types[0]=='Files' || e.dataTransfer.types[0].includes('file')) || isWebsiteImage)) { 
				if(!currentUser){ useMattoState.setState({ denyUpload:true}); return;}
				useMattoState.setState({ isUploading:true })
				let newKey:any = null
				let uploadedFile,isImage,toPath,fileName,fileType;
					
				const html = isWebsiteImage ? e.dataTransfer.getData("text/html") : null
				fileType = isWebsiteImage ? checkWebsiteFileType(html) : checkFileType(e.dataTransfer.files[0])
				if(fileType) {
					uploadedFile = isWebsiteImage ? fileType.src : e.dataTransfer.files[0];
					isImage = fileType.isImage;
					toPath = fileType.toPath; 
					fileName = fileType.fileName;
				}
				if(!isImage) {
					alert("Sorry - Cannot process this file. Only accept jpg,png, or webp");
					useMattoState.setState({ isUploading:false })
					return;
				}
				newKey = Math.random();
				const newPhysicalObject = { 
				...planePhysicalObject, 
				...{ key:newKey,
					dynamicMaterialProps:{repeat:{x:null, y:null}, offset:{x:null, y:null},width:500,height:700},
					type:'dynamic' } 
					};
					addObject(newPhysicalObject,e)	
			

			if (isImage) {
			    resizeImage(uploadedFile,1000,700,fileName).then( (imgCanvas:any) => {
						uploadImage(imgCanvas.id,imgCanvas,toPath,currentUser.uid).then( async (result:any) =>{
							useMattoState.setState({ isUploading:false })
							updateDynamicMaterialProp('files', result.files.file, newKey)
							updateDynamicMaterialProp('width', imgCanvas.width, newKey)
							updateDynamicMaterialProp('height', imgCanvas.height, newKey)
							
						})
						.catch(err=>{
							useMattoState.setState({ isUploading:false })										  
					})
				})
				.catch(err=>{
					useMattoState.setState({ isUploading:false });
					usePhysicalObjects.getState().deleteSelectedObject();
				})	
		 	}
		}
		else if(e.dataTransfer?.getData('upload')){
			//go here from Tab.
			const dynamicMaterialProps:DynamicMaterialProps = JSON.parse(e.dataTransfer?.getData('upload')) || null 	

			getCanvas(getUrlFromRef(dynamicMaterialProps.files)).then( (imgCanvas:any) => {
				dynamicMaterialProps.width=imgCanvas.width;
				dynamicMaterialProps.height=imgCanvas.height;
				const newKey = Math.random();
				const newPhysicalObject = {
					...planePhysicalObject, 
					...{ key:newKey,
						type:'dynamic',
						dynamicMaterialProps:dynamicMaterialProps}}
				addObject(newPhysicalObject,e)
			})			
		}
		else if(e.dataTransfer?.getData('logos')){
			const dynamicMaterialProps:DynamicMaterialProps = JSON.parse(e.dataTransfer?.getData('logos')) || null 	

			getCanvas(getUrlFromRef(dynamicMaterialProps.files)).then( (imgCanvas:any) => {
				const aspectRatio = imgCanvas.width / imgCanvas.height;
				dynamicMaterialProps.height=100;
				dynamicMaterialProps.width=dynamicMaterialProps.height*aspectRatio;
				if(dynamicMaterialProps.width<300){
					dynamicMaterialProps.width=300;
					dynamicMaterialProps.height=dynamicMaterialProps.width/aspectRatio;
				}
				const newKey = Math.random();
				const newPhysicalObject = {
					...planePhysicalObject, 
					...{ key:newKey,
						type:'dynamic',
						dynamicMaterialProps:dynamicMaterialProps}}
				addObject(newPhysicalObject,e)
			})			
		}
		else if (e.dataTransfer?.getData('canvasObject')){
			const canvasObject:CanvasObject = JSON.parse(e.dataTransfer?.getData('canvasObject')) || null 	
			const newPhysicalObject = {
				key: Math.random(),
				isStaticObject: false,
				type: ProductType.CANVAS,
				name:'canvasObject',
				scale: canvasObject.type=='text' ? [0.5,0.5,0.5] : [1,1,1,],
				canvasObject: canvasObject,        
			}
			addObject(newPhysicalObject,e)
		}
		else if(e.dataTransfer?.getData('material')){
			const materialDataReceived:any = e.dataTransfer?.getData('material') || null 	
			const materialDataInput = JSON.parse(materialDataReceived)
			
			const objType = materialObjectType[materialDataInput?.category?.length > 0 ? capitalize(materialDataInput.category[0]) : 'Tile'] || materialObjectType['Tile']

			if (materialDataInput?.materialData?.materialProps && objType=='hardEdgeSquare125x125_npwvc0jamq') {
				materialDataInput.materialData.materialProps.textureRepeat=2.0
			}
			
			getDocByID('productsV2', objType).then(doc=> {	
				const gltfMesh:any = doc.data()
				const newPhysicalObject = {
					id:generateID(),
					name: materialDataInput.name || gltfMesh?.name,
					key:Math.random(),
					productType: gltfMesh?.productType || 'not_static',
					type: materialDataInput?.type || ProductType.NOT_STATIC,
					isStaticObject: gltfMesh?.isStaticObject || false,
					objectStatus: materialDataInput.objectStatus,
					materialData: materialDataInput.materialData,
					preset:gltfMesh?.preset || null,
					mesh: gltfMesh.mesh,
					category: gltfMesh.category?.[0]
				}
				if(materialDataInput.paintMaterials) newPhysicalObject['paintMaterials'] = materialDataInput.paintMaterials
				if(materialDataInput.paintMaterialsData) newPhysicalObject['paintMaterialsData'] = materialDataInput.paintMaterialsData
				if(materialDataInput.id) newPhysicalObject['materialData']['id'] = materialDataInput.id
				if(materialDataInput.uid) newPhysicalObject['materialData']['uid'] = materialDataInput.uid
				addObject(newPhysicalObject,e)	
			})
            .catch(err => console.log("Error getting record!" + err));     
		}
		else if (e.dataTransfer?.getData('paint')){
			const materialDataReceived:any = e.dataTransfer?.getData('paint') || null 	
			const materialDataInput = JSON.parse(materialDataReceived)

			const objType = useStaging ? materialObjectType['Paint'] : materialObjectType['PaintProd'];

			getDocByID('productsV2', objType).then(doc=> {	
				const gltfMesh:any = doc.data()
				const newPhysicalObject = {
					id:generateID(),
					name: materialDataInput.name || gltfMesh?.name,
					key:Math.random(),
					productType: gltfMesh?.productType || ProductType.PAINTOBJECT,
					type: materialDataInput?.type || ProductType.PAINT,
					isStaticObject: gltfMesh?.isStaticObject || false,
					objectStatus: materialDataInput.objectStatus,
					materialData: materialDataInput.materialData,
					preset:gltfMesh?.preset || null,
					mesh: gltfMesh.mesh,
					category: gltfMesh.category?.[0]
				}
				if(materialDataInput.paintMaterials) newPhysicalObject['paintMaterials'] = materialDataInput.paintMaterials
				if(materialDataInput.paintMaterialsData) newPhysicalObject['paintMaterialsData'] = materialDataInput.paintMaterialsData
				if(materialDataInput.id) newPhysicalObject['materialData']['id'] = materialDataInput.id
				if(materialDataInput.uid) newPhysicalObject['materialData']['uid'] = materialDataInput.uid
				addObject(newPhysicalObject,e)	
			})
            .catch(err => console.log("Error getting record!" + err));  
		}
		else {
			let data:any = JSON.parse(e.dataTransfer?.getData('mesh') || null)				
			data = cleanGLTFMesh(data)
			if (!data) return;
			if (data.definedObject===true) {
				const meshId = data.id
				data = data.physicalObject
				if (data.meshId==null) data.meshId = meshId
			}			
			else { data.meshId = data.id }			
			if (data==null) return;
			data.refId = data.id
		 	if (data.id) delete data.id;	
			if (data.mesh) {
				data.scale = data.mesh.scale;
				data.rotation = data.mesh.rotation;
				data.meshTextureRepeat = data.mesh.meshTextureRepeat;
			}
			let objType = data.type
			if (!objType && data.mesh?.meshType) objType = data.mesh.meshType
			if (!objType) objType = (data.type=='gltf' || data.url) ? 'gltf' : 'Shape'				
			const newPhysicalObject:PhysicalObjectInterface = {...data, ...{type:objType,key:Math.random()}} 	
			addObject(newPhysicalObject,e)
		}
	}

const addObject = (newPhysicalObject,event) => {		
	let scale  = newPhysicalObject.isDynamic ? newPhysicalObject.scale ?? [1, 1, 1] :  newPhysicalObject.mesh?.scale ?? [1,1,1]
	if (newPhysicalObject.type==ProductType.CANVAS) scale = newPhysicalObject.scale ?? [1, 1, 1]

	if (Array.isArray(scale)===false ) scale = [scale,scale,scale]
	newPhysicalObject.scale = scale.map(x => x * 1)	
	const intersects = positionIn3D(gl,raycaster,camera,event)
	newPhysicalObject.position = [intersects.x,intersects.y,intersects.z]	
	newPhysicalObject.rotation =  newPhysicalObject.rotation  ?? [0,0,0]	
	newPhysicalObject.originalScale = newPhysicalObject.scale[0]

	const aspectRatio = newPhysicalObject?.materialData?.aspectRatio
	const scaleObject = newPhysicalObject.category=='Orthogonal' && aspectRatio && aspectRatio != 1

	if (scaleObject && aspectRatio > 1) {
		newPhysicalObject.scale[2] = newPhysicalObject.scale[2] / aspectRatio
	}
	else if (scaleObject && aspectRatio < 1) {
		newPhysicalObject.scale[0] = newPhysicalObject.scale[0] * aspectRatio
	}
	addPhysicalObject(newPhysicalObject,true)
}

	const updateDynamicMaterialProp = (prop,value,key) => {
        const selectedPhysicalObject= getSelectedPhysicalObject(key)  
        const newObj = produce(selectedPhysicalObject, draft => { draft.dynamicMaterialProps[prop]=value })
        updatePhysicalObject(newObj)
    }
	useEventListener('click', onClick, gl.domElement as any)
	useEventListener('dragover', onDragOver, gl.domElement as any)
	useEventListener('drop', onDrop, gl.domElement as any)
	return (null)
}



