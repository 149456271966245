import React, {useMemo,useRef,useEffect, useContext, useState} from 'react'
import {ImageItem} from '../libs/ImageItem'
import { unflattenObject, getMaterialThumbnail, cloudflareCDN, gtagViewItemEvent, materialDefaults2, deepClone, setProfileFirstAction } from '../libs/util'
import { Divider, Grid } from '@mui/material'
import { SearchFilter } from './SearchFilter'
import { produce } from 'immer'
import { getDocByID, getUrlFromRef } from '../libs/firebase'
import {RefinementDropdown} from './RefinementDropdown'
import { CustomSearchBox } from './CustomSearchBox'
import { FilterButton } from './FilterButton'
import { useGLTF } from '@react-three/drei'
import { ProductType, ProductInterface, ProfileFirstActions, ProfileInterface } from '../../../../packages/ts-interfaces';
import { useMattoState, usePhysicalObjects } from '../MattoState';
import { PhysicalObjectInterface } from '../ts/app_interfaces';
import { useLoginState } from "../studioxr/auth/LoginState";
import { UserProfileRole, ObjectStatusType } from 'ts-interfaces';
import { useInfiniteHits } from 'react-instantsearch';
import { useInView } from 'react-intersection-observer'
import { AuthContext } from "../auth/Auth";

//onclick these product types can be transformed
//paint below refers to paintObjects
const changeableProductTypes = [ProductType.NOT_STATIC, ProductType.DYNAMIC, ProductType.PAINT]

export const ProductHits = ({productType }) => {
    const rootGltfContainer = useRef<any>()
    const urlParams = new URLSearchParams(window.location.search);
    const supplier =  urlParams.get('defaultProductSupplier')
    
    const defaultProductSupplier = productType==ProductType.STATIC ? undefined : supplier ? [supplier] : undefined
    return (
    <Grid container ref={rootGltfContainer}   item xs={12} justifyContent="space-evenly" style={{ paddingTop:'15px', paddingLeft:'0px'}}>

        <Grid item xs={12} style={{alignItems:'center',padding:'20px 15px 20px 15px', marginLeft:'6px'}}>
            <CustomSearchBox placeholderText={productType==ProductType.NOT_STATIC ? 'Search Objects' : productType==ProductType.PAINTOBJECT ? 'Search Paint Objects' : 'Search Accessories'}/>
        </Grid>
        <Grid item xs={12} style={{paddingBottom:'2px'}} ><Divider /></Grid>

       {productType!=ProductType.PAINTOBJECT && <SearchFilter boundingBox={rootGltfContainer }  side='left'>
            <Grid item xs={12} style={{display:'flex', justifyContent:'center', height:'45px',paddingTop:'9px'}}>
                <FilterButton />
            </Grid>

            {productType==ProductType.STATIC && <RefinementDropdown showMore={false} limit={35} attribute='supplier' attributeName='Brand'  /> }
            <RefinementDropdown showMore={productType!=ProductType.STATIC} limit={25} attribute='category' attributeName='Category'  />
            {productType==ProductType.STATIC && <RefinementDropdown showMore={false} limit={35} attribute='color' attributeName='Color'  /> }
        </SearchFilter>}
        <CustomInfiniteHits  />
    </Grid>
    )
}

const CustomInfiniteHits = () => {
    const {currentUser} = useContext(AuthContext);
    const thumbnailsMaterialContainer = useRef<any>()
    const { hits,currentPageHits,results,isFirstPage,isLastPage,showPrevious,showMore,sendEvent} = useInfiniteHits();
	const { ref, inView, entry } = useInView({root:thumbnailsMaterialContainer.current,threshold: 0,});
    useEffect(() => {  
        if (inView==true  && isLastPage==false ) {  
            if (entry && entry?.intersectionRect?.top > 200) {
                showMore()
            }
        } 
    },[inView])

    
    return (
        <Grid container ref={thumbnailsMaterialContainer}  item xs={12} justifyContent="space-evenly" style={{maxHeight:'calc(100vh - 180px)', overflow:'auto', paddingTop:'10px', paddingLeft:'7px'}}>
            { hits.map( (product, index) => {
                return (
                    <Grid key={index} item xs={6} ref={ index === hits.length-1 ? ref : undefined } >
                        <ProductHit hit={product} currentUserUid={currentUser?.uid} />
                    </Grid>
                )
            })}
        </Grid>
    )    
}
export const ProductHit = ({hit, currentUserUid} ) => {
	const profile: ProfileInterface | any = useLoginState((state) => state.profile);
	const setShowLogin = useLoginState((state) => state.setShowLogin);
	const setShowSubscription = useMattoState((state) => state.setShowSubscription);
    const hitMod  = unflattenObject(hit)
    useEffect(() => {
        if (window.simulateDrop) return;
        window.simulateDrop = (id=1,offsetX=0,offsetY=0) => {
            const rect = window.gl.domElement.getBoundingClientRect()
            const testEvent:any = new CustomEvent('drop')
            testEvent.dataTransfer = new DataTransfer()			
            testEvent.clientX= rect.x + (rect.width/2) + offsetX
            testEvent.clientY= rect.y + (rect.height/2) + offsetY	
            getDocByID('productsV2',id).then(doc=>{
                if (!doc.exists) {
                    console.log("Could not find mesh with id:",id); return;
                }
                const gltfMesh = doc.data()                
                Object.defineProperty(testEvent, 'target', {writable: false, value: {mesh:doc}});
                onDrag(testEvent, gltfMesh)
                window.gl.domElement.dispatchEvent(testEvent)
            })
            .catch(err => console.log("Error getting record!" + err));     
        }        
    }, [])

    const onDrag = (e,gltf) => { 
        const data = {...gltf};
		if (data.objectStatus == ObjectStatusType.APPROVED_PRO && profile?.userRole !== UserProfileRole.PRO) { if(!profile?.uid) setShowLogin('signup'); else setShowSubscription('startTrial'); return; }
        if(data.productType==ProductType.STATIC) {
            gtagViewItemEvent(data)
            setProfileFirstAction(ProfileFirstActions.DRAG_PRODUCT).finally(()=>{})
        } 
        else if (data.productType==ProductType.NOT_STATIC || data.productType==ProductType.MATERIAL) {
            setProfileFirstAction(ProfileFirstActions.DRAG_OBJECT).finally(()=>{})
        }
        else if (data.productType==ProductType.PAINTOBJECT) {
            setProfileFirstAction(ProfileFirstActions.DRAG_PAINT).finally(()=>{})
        }
        if( gltf?.category != 'Freeform models'){
            data.url = getUrlFromRef(data?.mesh.web_sized_glb)
            if (data.url) useGLTF.preload(getUrlFromRef(data.url,true));	            	
                const filteredData = produce(data, draft=> {
                    delete draft.triangles; delete draft.compression; delete draft.size_kb; delete draft.thumbnailSize;
                    delete draft.description; delete draft.tags; delete draft.updatedAt;
            })  
            e.dataTransfer.setData("mesh", JSON.stringify(filteredData))            
         }
         else {   e.dataTransfer.setData("mesh", JSON.stringify(data))  }
    } 

    const getImage = (gltf) => {
        if (gltf.mesh?.rendered_image) return cloudflareCDN(gltf.mesh?.rendered_image,'height=90,format=auto')
        if (gltf.files?.thumbnail) { return getUrlFromRef(gltf.files?.thumbnail) }
        if (gltf.mesh?.thumbnail) { return cloudflareCDN(gltf.mesh?.thumbnail,'height=90,format=auto') }
        return undefined;
    }
    const handleClick = (e,product:ProductInterface) => {
		if (product.objectStatus == ObjectStatusType.APPROVED_PRO && profile?.userRole !== UserProfileRole.PRO) { if(!profile?.uid) setShowLogin('signup'); else setShowSubscription('startTrial'); return; }

        const physicalObject:PhysicalObjectInterface = usePhysicalObjects.getState().getCurrentSelectedPhysicalObject()
        if ( product?.id != physicalObject?.refId && changeableProductTypes.includes(physicalObject?.type) && (physicalObject?.type == product.productType || physicalObject?.type == ProductType.PAINT && product.productType == ProductType.PAINTOBJECT)) {
            const scale = Array.isArray(product.mesh?.scale) ? product.mesh?.scale : [product.mesh?.scale,product.mesh?.scale,product.mesh?.scale]
            const newPhysicalObject:PhysicalObjectInterface= {
                name:product.name,isStaticObject:false,
                type: physicalObject.type,
                mode:'material',
                scale:scale as number[] || [1,1,1],
                rotation:product.mesh?.rotation || [0,0,0],
                meshTextureRepeat: product.mesh?.meshTextureRepeat || 1,
                refId:product.id,
                position:physicalObject.position,
                url: getUrlFromRef(product.mesh?.web_sized_glb),
                key:Math.random()
            }   
            const newMaterialData = produce(physicalObject.materialData, draft => {                
                if (draft) draft.materialProps = deepClone(materialDefaults2)
            })
            newPhysicalObject['materialData'] = newMaterialData
            if (physicalObject.dynamicMaterialProps) newPhysicalObject['dynamicMaterialProps'] = physicalObject.dynamicMaterialProps
            if (physicalObject.paintMaterials) newPhysicalObject['paintMaterials'] = physicalObject.paintMaterials
            if (physicalObject.paintMaterialsData) newPhysicalObject['paintMaterialsData'] = physicalObject.paintMaterialsData
            usePhysicalObjects.getState().deleteSelectedObject()
            usePhysicalObjects.getState().addPhysicalObject(newPhysicalObject,true) //where true is draggedIn
            setProfileFirstAction(ProfileFirstActions.TRANSFORM_OBJECT)            
        }
    }
    return ( 
        <ImageItem 
            name={hitMod.name}
            src={getImage(hitMod)} 
            id={hitMod.id} 
            objectStatus={hitMod.objectStatus}
            onClick={(e)=>handleClick(e,hitMod)} 
            onDragStart={(e)=>onDrag(e,hitMod)} 
            draggable={true}
            currentUserUid={currentUserUid}    
        /> 
    )
}
