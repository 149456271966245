import React, { useMemo,useRef, useState,useEffect, Suspense } from 'react'
import { Align, CanvasObject, PhysicalObjectInterface, SideBarMode } from '../../ts/app_interfaces';
import { CanvasTexture, LinearFilter, BoxGeometry, Quaternion,SRGBColorSpace, TextureLoader,Vector3, Matrix4, MeshDepthMaterial, RGBADepthPacking  } from 'three';
import { invalidate, useFrame, useLoader, useThree  } from '@react-three/fiber';
import { commonTypefaces, positionIn3D, setProfileFirstAction } from '../../libs/util';
import { CanvasLineObject } from './CanvasLineObject';
import { ProfileFirstActions } from 'ts-interfaces';
import { drawText } from 'canvas-txt';

export const CanvasGLTFObject = (props) => {
    const {physicalObject, onLoad} = props;

    if(physicalObject.canvasObject.type=='text') {
        setProfileFirstAction(ProfileFirstActions.DRAG_TEXT).finally(()=>{});
        return (
            <Suspense fallback={null}>
                <CanvasTextObject physicalObject={physicalObject} onLoad={onLoad} />
            </Suspense>
        )
    } else {
        setProfileFirstAction(ProfileFirstActions.DRAG_LINE).finally(()=>{});
        return (
            <Suspense fallback={null}>            
                <CanvasLineObject physicalObject={physicalObject} onLoad={onLoad} />
            </Suspense>
        )
    }
}
const CanvasTextObject = ({physicalObject,onLoad}) => {
    const planeRef:any = useRef();
    const materialRef:any = useRef();
    const [loaded,setLoaded] = useState(false)

    const createText = () => {
        const canvas = createCanvasWithText(physicalObject.canvasObject);
        const [w,h] = [canvas.width, canvas.height]
        return [ createCanvasTexture(canvas),  createPlaneGeometry(w*0.05,h*0.05)]
    }
    
    const [dynamicTexture,planeGeometry]:any = useMemo(() => {  
        return createText()
    }, [physicalObject.canvasObject]);

    useEffect(() => {      
        if (materialRef.current && planeRef.current && dynamicTexture) {            
            materialRef.current.map = dynamicTexture;
            materialRef.current.map.flipY = true;
            materialRef.current.alphaTest = 0.1;
            materialRef.current.envMapIntensity = 1.0;
            materialRef.current.metalness =  0.0;
            materialRef.current.transparent = true;
            materialRef.current.shadowSide = 0;
            materialRef.current.map.needsUpdate = true;
            materialRef.current.side = 0;
        }
    }, [materialRef, dynamicTexture])

    useEffect(() => { 
        if (planeRef.current && loaded==false)  {  setLoaded(true); onLoad();  } 
        invalidate()
    }, [planeRef.current])
    
    return (
        <Suspense fallback={null}>
            <mesh name="text" ref={planeRef} castShadow={false} receiveShadow={false} geometry={planeGeometry} >
                <meshBasicMaterial attach="material-0"   transparent  opacity={0} />
                <meshBasicMaterial attach="material-1"   transparent  opacity={0}/>
                <meshStandardMaterial attach="material-2" envMapIntensity={1} ref={materialRef} />                    
                <meshBasicMaterial attach="material-3"     transparent  opacity={0} />
                <meshBasicMaterial attach="material-4"    transparent  opacity={0} />
                <meshBasicMaterial attach="material-5"    transparent opacity={0} />
            </mesh>
        </Suspense>
    )
}

const defaultFont = Object.keys(commonTypefaces).slice(-1)[0]

export const createCanvasWithText = (canvasObject:CanvasObject) => {
    let font = commonTypefaces[canvasObject.fontFamily || defaultFont  ] ??  commonTypefaces[defaultFont]
    let alignment: Align = 'center';
    font = font.replace(/['"]+/g, '');
    font =  (canvasObject.fontSize ?  canvasObject.fontSize :  '140' ) + 'px' + ' ' + font

    if (canvasObject.style) font = canvasObject.style.join(' ') + ' ' + font;
    if(canvasObject.alignment) alignment = canvasObject.alignment;
    const canvas = document.createElement('canvas');
    if (!canvasObject.text) return canvas; //empty
    const ctx:any = canvas.getContext('2d');
    ctx.font = font
    const metrics = ctx.measureText(canvasObject.text);
    const metricHeight = metrics.fontBoundingBoxAscent + metrics.fontBoundingBoxDescent;

    const lines = canvasObject.text.split('\n').length
    
    // get max width for the text from different lines
    let maxWidth = 0;
    canvasObject.text.split('\n').forEach((line) => {
        const width = ctx.measureText(line).width;
        if (width > maxWidth) {
            maxWidth = width;
        }
    });

    canvas.width=maxWidth + 19
    canvas.height=(metricHeight*lines) + 40

    ctx.font =  font
    ctx.textRendering = "optimizeLegibility";

    if(canvasObject.backgroundColor && !canvasObject.transparent) {
        ctx.rect(0, 0, canvas.width, canvas.height);
        ctx.fillStyle = canvasObject.backgroundColor || 'rgba(1,1,0,0)';
        ctx.fill();   
    }

    ctx.fillStyle= canvasObject.color || '#000000'

    // ctx.fillStyle =   canvasObject.backgroundColor ?? 'rgba(0,0,0,0)';
    // ctx.fillRect(0,0,canvas.width,canvas.height);
    // ctx.fillText(canvasObject.text, 0, height + 1);

    const { height } = drawText(ctx, canvasObject.text as string, {
        x: 0, y: 0,
        align:alignment,
        width: canvas.width,height: canvas.height,
        fontSize: canvasObject.fontSize ? canvasObject.fontSize as any : 140,
        font: commonTypefaces[canvasObject.fontFamily || defaultFont  ] ??  commonTypefaces[defaultFont],
        fontStyle: canvasObject.style ? canvasObject.style.join(' ') : 'normal',
    })    
    return canvas;
}

export const createPlaneGeometry = (width, height) => new BoxGeometry(width, 0.1, height, 2, 2);

export const createCanvasTexture = (src) => {
    const canvasText:any = new CanvasTexture(src)
    canvasText.minFilter = LinearFilter;
    canvasText.maxFilter = LinearFilter;
    canvasText.colorSpace = SRGBColorSpace;
    canvasText.center.set(0.5, 0.5);    
    return canvasText
}

