import React from 'react'
import { createRoot } from 'react-dom/client';
import './index.css'
import App from './App'
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { firebaseConfig,setupHelpers } from "./libs/firebase";
import { firebaseHelper } from 'ui';

firebaseHelper.initializeApp(firebaseConfig);
setupHelpers();

const useStaging = !    (window.location.hostname.includes('mattoboard.com') 
						            || window.location.hostname.includes('web.app'))


Sentry.init({
  dsn: "https://5c18a30196a940c9ade7515923c03671@o1220868.ingest.sentry.io/6367335",
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
  enabled: !useStaging,
  beforeSend(event, hint) { 
      // if (event.exception) {
      //   Sentry.showReportDialog({ eventId: event.event_id });
      // }
      return event;
    }
});

const container = document.getElementById('root');
const root = createRoot(container); 
// newer version uses React18
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)
