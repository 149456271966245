import React, { useState } from 'react'
import { PhysicalObjectInterface } from '../ts/app_interfaces';
import { useMattoState, usePhysicalObjects, useProjectData } from '../MattoState'
import { useLoginState } from '../studioxr/auth/LoginState';
import { ProductInterface, ProfileInterface, ProductType, UserProfileRole, MaterialFiles } from 'ts-interfaces';
import { Snackbar } from '@mui/material';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { Tooltip, IconButton } from '@mui/material';
const loadJSZip = () => import('jszip');

//const loadJSZip = () => import('../scripts/jszip.min.js');
//const loadJSZip = () => import('/files/jszip.min.js?url');

export const ProductDownloadButton = () => {
    const selectedPhysicalObjectKey = usePhysicalObjects(state=>state.selectedPhysicalObjectKey)
    const getSelectedPhysicalObject = usePhysicalObjects( (state) => state.getSelectedPhysicalObject)
    const selectedPhysicalObject:PhysicalObjectInterface = getSelectedPhysicalObject(selectedPhysicalObjectKey)
    const profile: ProfileInterface | any = useLoginState((state) => state.profile);
    const setShowLogin = useLoginState((state) => state.setShowLogin);
    const [downloading, setDownloading] = useState(false);
    const [snackOpen,setSnackOpen]=useState(false)
    const [snackMessage,setSnackMessage]=useState('')
    const backgroundTexture:ProductInterface | any = useProjectData(state=>state.backgroundTexture)
	const setShowSubscription = useMattoState((state) => state.setShowSubscription);

    const downloadObject = async () => {
        if(profile?.userRole !== UserProfileRole.PRO) {  
            if(!profile?.uid) setShowLogin('signup'); 
            else setShowSubscription('startTrial'); return; 
        }

        setDownloading(true);

        if(selectedPhysicalObject?.isStaticObject) {
            const mesh = selectedPhysicalObject?.mesh?.full_sized_glb;
            const a = document.createElement('a');
            if(mesh) {
                setSnackOpen(true);
                setSnackMessage('Please wait while the file is being prepared for download...');
                // download the mesh file
                const response = await fetch(mesh);
                const blob = await response.blob();

                // download the blob
                const blobUrl = URL.createObjectURL(blob);
                a.href = blobUrl;
                a.download = `${selectedPhysicalObject?.name}.glb`;
                a.target = '_blank';
                a.click();
                setSnackOpen(false);
                URL.revokeObjectURL(blobUrl);
            }
            setDownloading(false);
        } else if(selectedPhysicalObject?.type == ProductType.NOT_STATIC) {
            // zip the files and download
            const files = selectedPhysicalObject?.materialData?.files;
            
            if (files) zipAndDownloadFiles(files, selectedPhysicalObject?.materialData?.name);
            else {
                setSnackOpen(true);
                setSnackMessage('No files to download');
                setDownloading(false);
            }
        } else if(selectedPhysicalObjectKey == null && backgroundTexture != null && backgroundTexture?.productType == ProductType.MATERIAL) {
            const files = backgroundTexture?.materialData?.files;
            if(files) zipAndDownloadFiles(files, backgroundTexture?.materialData?.name);
            else {
                setSnackOpen(true);
                setSnackMessage('No files to download');
                setDownloading(false);
            }
        }
    }

    const zipAndDownloadFiles = async (files: MaterialFiles, name: string | any) => {
        setSnackOpen(true);
        setSnackMessage('Please wait while the files are being prepared for download...');


        const x = await loadJSZip()
        const zip = new x.default();

        //loadJSZip().then(async (module) => {            
            
            //const zip = new JSZip();
            for (const [key, value] of Object.entries(files)) {
                const response = await fetch(value);
                const blob = await response.blob();
                //const filename = value.substring(value.lastIndexOf('/') + 1);
                const filename = key + '.jpg'
                zip.file(filename, blob);
            }
            zip.generateAsync({type:"blob"})
            .then(function(content) {
                const a = document.createElement('a');
                a.href = URL.createObjectURL(content);
                a.download = `${name}.zip`;
                a.target = '_blank';
                a.click();
                setSnackOpen(false);
                setDownloading(false);
            });
       // });
    }

    const TooltipIcon = (props) => {

        return (        
            <Tooltip style={props.style} title={
                <React.Fragment>
                    Download 3D Object (Pro)
                </React.Fragment>

            } arrow>
                <span>
                    <IconButton disabled={props.downloading} onClick={props.handleClick} edge='start' size='small'>
                    {props.children}
                    </IconButton>
                </span>
            </Tooltip>
        )
    }

    return (
        <>
            <TooltipIcon title='Download 3D Object (Pro) <a href="/foo">fff</a>' handleClick={downloadObject} downloading={downloading}><FileDownloadOutlinedIcon /></TooltipIcon>
            <Snackbar 
                onClose={() => setSnackOpen(false) }
                anchorOrigin={{vertical: 'bottom',horizontal: 'center'}}  
                ClickAwayListenerProps={{ onClickAway: () => null }}
                open={snackOpen} message={snackMessage} />
        </>
    )

}