import { Badge, Button, Dialog, DialogActions,  DialogContent, DialogContentText, DialogTitle, IconButton, Modal, SvgIcon, Tooltip, Typography, createSvgIcon } from '@mui/material'
import React, { useContext,useState,useEffect } from 'react'
import InboxSharpIcon from '@mui/icons-material/InboxSharp';
import { AuthContext } from '../auth/Auth';
import { useRenderStore } from './DeepRenderData';
import { Stack, styled } from '@mui/system';
import { cloudflareCDN } from 'ui';
import { DeepRenderInterface } from './DeepRenderData';
import { capitalize, getTimeAgo, urlToCanvas } from '../libs/util';

const DialogImage = styled('img')({ borderRadius:'10px', width:'200px', objectFit:'cover' })

const DeepRenderIcon= createSvgIcon(
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
    width="100.000000pt" height="100.000000pt" viewBox="0 0 100.000000 100.000000"
    preserveAspectRatio="xMidYMid meet">

    <g transform="translate(10.000000,110.000000) scale(0.100000,-0.100000)"
    fill="#000000" stroke="none">
    <path d="M655 893 c-66 -20 -73 -28 -37 -47 23 -12 48 -14 109 -9 78 6 80 5
    101 -22 28 -35 28 -83 1 -163 -76 -226 -362 -478 -557 -490 -49 -4 -67 -1 -82
    13 -35 32 -38 121 -4 108 13 -5 14 4 8 58 -3 35 -10 68 -14 72 -4 5 -34 -8
    -66 -28 -58 -37 -58 -38 -36 -53 19 -12 22 -23 22 -81 0 -47 5 -75 19 -97 61
    -99 230 -81 420 46 153 103 299 279 353 426 30 81 31 182 2 221 -45 61 -134
    78 -239 46z"/>
    <path d="M270 845 c-57 -23 -106 -47 -108 -53 -1 -5 50 -31 114 -56 l117 -47
    118 47 c66 26 119 50 119 54 0 9 -224 100 -243 99 -6 0 -59 -20 -117 -44z"/>
    <path d="M126 732 c-4 -3 -6 -63 -6 -133 0 -150 -11 -136 144 -198 78 -32 98
    -37 102 -26 4 8 2 72 -2 141 l-9 127 -112 47 c-62 26 -114 45 -117 42z"/>
    <path d="M560 701 c-153 -61 -138 -39 -133 -193 3 -73 8 -136 12 -139 3 -4 49
    10 101 30 156 61 141 40 138 198 -2 80 -7 137 -13 139 -5 2 -53 -14 -105 -35z"/>
    </g>
    </svg>, 'Deep Render'
)


export const DeepRenderResults = () => {
    const {currentUser} = useContext(AuthContext);
    const results = useRenderStore((state:any) => state.results);
    const unviewed = useRenderStore((state:any) => state.unviewed);
    const setViewed = useRenderStore((state:any) => state.setViewed);

    const [open, setOpen] = useState(false);
    const handleClose = () => setOpen(false);
    useEffect(() => {
        //return () => { (useRenderStore.getState() as any).unsubscribe?.() }
    },[])

    useEffect(() => {
        if (currentUser?.uid) {
            (useRenderStore.getState() as any).fetch(currentUser.uid)
        }
    },[currentUser?.uid])

    const handleClick = (result:DeepRenderInterface) => {
        if (result.render_status!='done') return;
        setViewed(result.id)
        window.open(result.rendered_url,'_blank')
    }
    const getImage = (r) => {
        if (r.rendered_url) {
            return <DialogImage src={ cloudflareCDN(r.rendered_url, 'width=200')} onClick = { ()=>handleClick(r) } />
        }
        else {  return <DialogImage src={generateWhiteImg(100,100)}  /> }
    }
    const handleDownload =  async (result) => {
        setViewed(result.id)
        const canvas:any = await urlToCanvas(result.rendered_url)
        const link = document.createElement('a');
        link.href = canvas.toDataURL(result.format=='png' ? 'image/png' : 'image/jpeg')                
        link.download = (result.project_name || 'Untitled') + (result.format == 'png' ? '.png' : '.jpg');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link)
    }
    const getStatus = (r) => {
        if (r.render_status=='failed' || (r.render_status=='queued' && r.createdAt < Date.now() - 1 * 60 * 60 * 1000)) return 'Failed to Render'
        else return capitalize(r.render_status)
    }

    if (currentUser?.uid==null) return null;
    return (
        <>
            <Tooltip title="Deep Render Results">
            <IconButton onClick={ ()=> {setOpen(true)}} >
            <Badge sx={{paddingRight:'5px'}} badgeContent={unviewed} color="primary">
                <DeepRenderIcon color="action" style={{fontSize:'30px'}} />
            </Badge>
            </IconButton>
            </Tooltip>
        <Dialog onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={open}
        >
        <DialogTitle>Deep Render Results</DialogTitle>

        <DialogContent >
            {results.length==0 && 
                <DialogContentText>
                    No renders yet.  Try a Deep Render from Download Button
                </DialogContentText>            
            }

            {results.map( (r:any) => {
                return (
                    <Stack  key={r.id} direction="row" spacing={2} sx={{ cursor: r.render_status=='done' ? 'pointer' : 'auto', padding:'10px',borderBottom:'1px solid #ccc'}}>
                        { getImage(r) }

                        <Stack direction="column" spacing={1}>
                        <Typography sx={{fontWeight: r.viewed==false  && r.render_status=='done' ? 900 : 500}} variant="body2">Project:{r.project_name || 'Untitled'}</Typography>
                        <Typography sx={{fontWeight: r.viewed==false  && r.render_status=='done' ? 900 : 500}}  variant="body2">Status: {getStatus(r)}</Typography>
                        <Typography sx={{fontWeight: r.viewed==false  && r.render_status=='done' ? 900 : 500}}  variant="body2">Started: {getTimeAgo(r.createdAt)}</Typography>
                        <Typography sx={{fontWeight: r.viewed==false  && r.render_status=='done' ? 900 : 500}}  variant="body2"><a onClick={ () => { handleDownload(r) } } href='#'>Download</a></Typography>
                        <Typography sx={{color:'white'}} variant="body2">{r.id}</Typography>

                        </Stack>
                    </Stack>
                )
            })}        
        </DialogContent>
        <DialogActions>
            <Typography
                sx={{cursor:'pointer'}}
                onClick={ () => { window.open('https://www.youtube.com/watch?v=r-ru4Pl5mwU','_blank') }} 
                variant="caption">Learn More About Deep Render</Typography>
        </DialogActions>
        </Dialog>
        </>

    )
}
function generateWhiteImg (width:number, height:number) {
    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;
    const ctx:any = canvas.getContext('2d');
    ctx.fillStyle = 'white';
    ctx.fillRect(0, 0, width, height);
    return canvas.toDataURL('image/jpeg');
}
