import React, { useEffect, useState} from 'react'
import { Button, Tooltip, Snackbar } from '@mui/material'
import { useProjectData, usePhysicalObjects, useMattoState } from '../MattoState'
import { styled } from '@mui/system';
import { osName, isSafari, isMacOs, osVersion } from 'react-device-detect';
import { useLoginState } from '../studioxr/auth/LoginState';
import { ProfileInterface, UserProfileRole } from 'ts-interfaces';
import useEventListener from '../libs/useEventListener';
import { invalidate } from '@react-three/fiber';
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';
import {BarIconButton} from './ButtonStyles';

const RecordBtn = styled(Button)(({ theme }:any) => ({ color:'red', border: '1px solid red', marginRight:'7px', 
                                                  maxHeight:theme.subBarButtons.maxHeight,'&:hover':{'color':'white','backgroundColor':'red'}}));

// Apply the keyframe animation using styled from @mui/system
const Recording = styled(`div`)({
    width: '10px',
	height: '10px',
    marginLeft: '5px',
    backgroundColor: 'red',
    borderRadius: '50%',
    animation: 'pulse 1.5s infinite linear',
    boxShadow: '0px 0px 5px 0px rgba(173,0,0,.3)',
    animationName: 'pulse',
    '@keyframes pulse': {
        '0%': {
            boxShadow: '0px 0px 5px 0px rgba(173,0,0,.3)',
        },
        '50%': {
            boxShadow: '0px 0px 5px 8px rgba(173,0,0,.3)',
        },
        '100%': {
            boxShadow: '0px 0px 5px 0px rgba(173,0,0,.3)',
        },
    },
});



export function RecordButton() {
	const setSelectedPhysicalObjectKey = usePhysicalObjects( (state) => state.setSelectedPhysicalObjectKey)
	const projectData = useProjectData()
	const saveProjectNow = useMattoState((state) => state.saveProjectNow);
    const [recording, setRecording] = useState(false);
    const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder>();
	const [openSnack, setOpenSnack]=useState(false)
    const profile: ProfileInterface | any= useLoginState((state) => state.profile);
    const setShowLogin = useLoginState((state) => state.setShowLogin);
	const setShowSubscription = useMattoState((state) => state.setShowSubscription);
    const [snackMessage, setSnackMessage] = useState<string>('');
    const gpuData:any = useMattoState((state) => state.gpuData);
    const [showRecordButton, setShowRecordButton] = useState(false);
	const setDoAnimate = useMattoState((state) => state.setDoAnimate);

    const doShowRecordButton = () => {
        if (gpuData?.fps < 40 || typeof MediaRecorder === 'undefined') return false;
        return true;
    }

    useEffect(() => {
        setShowRecordButton(doShowRecordButton());
        if (!MediaRecorder.isTypeSupported('video/webm') && !MediaRecorder.isTypeSupported('video/mp4')) {
            setShowRecordButton(false);
        } 
        // cleanup
        return () => {
            mediaRecorder && mediaRecorder.stop();
        }

    },[]);

	const handleClick = () => {
        if(profile?.userRole !== UserProfileRole.PRO) {  
            if(!profile?.uid) setShowLogin('signup'); 
            else setShowSubscription(['startTrial', 'Sign Up for Pro for Unlimited Recordings']); return; 
        }

		if(!projectData.projectId)
		    alert('Please save your project before recording.');
		else {
			setSelectedPhysicalObjectKey(null)
            if(!recording) {
                startRecording();
            } else {
                saveRecording();
            }
		}
	}

    const handleKeyDown = (e) => {
        if (e.target!=document.body || !recording) return;
        if (e.key=='l' || e.key=='L') {
            e.preventDefault()
            e.stopPropagation()
            setDoAnimate(true);
        }
        if (e.key=='Escape') {
            e.preventDefault()
            e.stopPropagation()
            saveRecording();
        }
    }

    // test mimetypes
    // video/mp4
    // video/webm
    // video/x-matroska
    // video/quicktime
    // video/mpeg
    // video/3gpp
    // video/3gp2
    // video/mp4;codecs:h264 
    // video/mp4;codecs=avc1.4d002a    
    // video/webm;codecs=vp9
    // video/webm;codecs=vp8,opus
    // video/webm;codecs=h264
    // video/x-matroska;codecs=avc1,opus
    // video/quicktime;codecs=h264
    // video/quicktime;codecs=avc1
    // video/quicktime;codecs=mp4a.40.2

    const startRecording = () => {
        try {
            console.log("HELLOO");
            // Find the canvas element to capture
            const canvasElt = window.gl.domElement;
            setOpenSnack(true);
            setSnackMessage('Recording Scene. Press "L" to smoothly sway the light');
            // Get the stream
            if(canvasElt instanceof HTMLCanvasElement) {
                const stream = canvasElt.captureStream(); // 60 FPS
                let mimeType = 'video/webm';
                const videoBitsPerSecond =  40000000
                if (isSafari===true) {
                    mimeType = 'video/mp4';
                }
                const mediaRecorder = new MediaRecorder(stream, { mimeType, videoBitsPerSecond });
                setMediaRecorder(mediaRecorder);
                const chunks: Blob[] = [];
                mediaRecorder.ondataavailable = e => {
                    chunks.push(e.data);
                };
                mediaRecorder.onstop = () => {
                    const blob = new Blob(chunks, { type: mimeType });
                    const reader = new FileReader();
                    reader.onload = function(event) {
                        if(event.target === null) return;
                        const url = event.target.result;
                        const a: any = document.createElement("a");
                        document.body.appendChild(a);
                        a.style = 'display: none';
                        a.href = url;
                        const extension = mimeType === 'video/mp4' ? 'mov' : 'webm';
                        const filename = projectData.title.replace(/[^a-z0-9]/gi, '_').toLowerCase();
                        a.download = `${filename}.${extension}`;
                        a.click();
                        setTimeout(() => {
                            document.body.removeChild(a);
                        }, 100);
                    };
                    reader.readAsDataURL(blob);
                };
                mediaRecorder.start();
                setRecording(true);
            }
        }        
        catch(e) {
            console.log(e);
            setOpenSnack(false);
            setSnackMessage('');
        }
    }

    const saveRecording = () => {
        setRecording(false);
        setOpenSnack(true);
        setSnackMessage('Recording Saved');
        // stop media recorder
        mediaRecorder && mediaRecorder.stop();
        setMediaRecorder(undefined);
        setTimeout(() => {
            setOpenSnack(false);
        }, 3000);
    }

    // create event listener for arrow keys
    useEventListener('keydown', handleKeyDown);

    if(!showRecordButton) return null;

	return (
        <React.Fragment>
			{!recording &&
                <Tooltip title="Record" arrow>
                    <BarIconButton
                        onClick={handleClick}
                        disabled={(saveProjectNow ) ? true: false}
                        edge='start'
                        size="large">
                        <VideocamOutlinedIcon style={saveProjectNow===true ? {color:'grey'}: {color:'black'}} />
                    </BarIconButton>    
                </Tooltip>
            }
            {recording &&
            <>
                <Tooltip title="Recording" arrow>
                    <BarIconButton
                        onClick={handleClick}
                        disabled={false}
                        edge='start'
                        size="large"
                        >
                        <VideocamOutlinedIcon sx={{
                            animation: 'colorPulse 2s infinite',
                            '@keyframes colorPulse': {
                                '0%, 100%': {
                                color: 'black',
                                },
                                '50%': {
                                color: 'red',
                                },
                            }
                        }} />
                    </BarIconButton>
                </Tooltip>
            </>
            }
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left',}}
                open={openSnack}
                onClose={ () => { }}
                autoHideDuration={null}
                message={snackMessage}
            />
		</React.Fragment>
    );
}