import React, { useState, useEffect, useContext } from 'react'
import {ButtonGroup, Button,Grid} from '@mui/material'
import { proCuratedTemplateIDs, proBlankTemplateIDs, getUrlFromRef, curatedTemplateUserID} from '../libs/firebase';
import { useMattoState, useProjectData, usePhysicalObjects} from '../MattoState'
import { projectsDB, templateUserID} from '../libs/firebase'
import { query, where, getDocs, orderBy, limit, startAfter } from "firebase/firestore";
import { ScrollableGrid } from './ScrollableGrid';
import { resetCameraToOriginalPosition } from "../buttons/ResetCamera"
import { SideBarMode } from '../ts/app_interfaces';
import { styled } from '@mui/system';
import { cloudflareCDN } from 'ui';
import { AuthContext } from "../auth/Auth";
import { useLoginState } from "../studioxr/auth/LoginState";
import { ProfileInterface, UserProfileRole } from 'ts-interfaces';
import { produce } from 'immer';
import { TabButton } from '../buttons/ButtonStyles';


export function TemplateTab(props) {
	const projectData = useProjectData()
	const resetProjectData = useProjectData(state=>state.reset)
	const updateProjectData = useProjectData(state=>state.update)
	const setPhysicalObjects = usePhysicalObjects( (state) => state.setPhysicalObjects)
	const setSelectedPhysicalObjectKey = usePhysicalObjects( (state) => state.setSelectedPhysicalObjectKey)
	const [templates, setTemplates]=useState<any>([])
	const [isLoading,setIsLoading]=useState(false)
	const setStateDirty = useMattoState((state) => state.setStateDirty)
	const controls = useMattoState((state) => state.controls)
    const sideBarMode = useMattoState((state) => state.sideBarMode);
	const [templateType,setTemplateType]=useState('curated')  //blank or curated
	const [blankTemplates, setBlankTemplates]=useState<any>([])
	const [curatedTemplates, setCuratedTemplates]=useState<any>([])

//	const curatedAndProCuratedTemplateIDs = [...curatedTemplateIDs, ...proCuratedTemplateIDs];

	const {currentUser} = useContext(AuthContext);
	const setShowLogin = useLoginState((state) => state.setShowLogin);
	const profile: ProfileInterface | any = useLoginState((state) => state.profile);
	const setShowSubscription = useMattoState((state) => state.setShowSubscription);
	const proTemplateIDs = [...proBlankTemplateIDs, ...proCuratedTemplateIDs];
	
    const loadItems = () => { if (isLoading==false) { loadTemplates() } }
    const loadTemplates = async() => {
		
		if (templateType=='blank' && blankTemplates.length > 0) {
			setTemplates(blankTemplates)
			return;
		}
		else if (templateType=='curated' && curatedTemplates.length > 0) {
			setTemplates(curatedTemplates)
			return;
		}
		setIsLoading(true)
		setTemplates([])
		let templatesTemp:any = []

		if (templateType=='curated') templatesTemp = await fetchTemplates(curatedTemplateUserID)
        else  templatesTemp = await fetchTemplates(templateUserID)
		
		const sortedTemplates = templatesTemp?.sort(function(a:any, b:any){return a.physicalObjects.length - b.physicalObjects.length}) ?? []		
		const templatesRemoveArchived = sortedTemplates.filter((template:any) => template?.archive != true)
		
		if (templateType=='blank') setBlankTemplates([...templatesRemoveArchived])
		else setCuratedTemplates([...templatesRemoveArchived])
		setTemplates([...templatesRemoveArchived])
		if (window.gtag) {
			window.gtag('event', 'load_template', { 'event_category': 'engagement', 'event_label': templateType });
		}
	}
	const fetchTemplates = async (userID) => {
		try {
			let a:any = []
			const templatesTemp:any = []
			a = [projectsDB, where("user", "==", userID),limit(100)]
			const q = query.apply(this, a)
			const snapshot = await getDocs(q);
			snapshot.docs.map( (doc:any) => templatesTemp.push(doc.data()));
			setIsLoading(false)	
			return templatesTemp;
		} catch (error) {
			console.error('Error fetching templates:', error);
		}
	}
    useEffect(() => {  if (sideBarMode != SideBarMode.Templates) return; loadItems() },[templateType,sideBarMode])

	const handleClick = (template) => {	
		if (proTemplateIDs?.includes(template.id) && profile?.userRole !== UserProfileRole.PRO) { if(!currentUser?.uid) setShowLogin('signup'); else setShowSubscription('startTrial'); return; }
		else {
			const isStateDirty = useMattoState.getState().isStateDirty
			if (isStateDirty==true || isTemplateDifferent(template)==true) {
				const x = window.confirm("Project unsaved! Are you sure you want to change the template?")
				if (x!=true) return;
			}		
			const data = JSON.parse(JSON.stringify(template))
			const projectId = projectData.projectId ?? null
			setSelectedPhysicalObjectKey(null)
			setStateDirty(false)
			resetProjectData()
			setPhysicalObjects(JSON.parse(JSON.stringify(template.physicalObjects)))

			if (templateType=='curated') {
				let backgroundTexture = data.backgroundTexture;
				if(data.backgroundTexture) {				
					backgroundTexture = data.backgroundTexture?.materialData?.materialProps ? data.backgroundTexture  : 
					produce(data.backgroundTexture, draft => { draft.materialData = { 
																	files: data.backgroundTexture.files, 
																	materialProps: data.backgroundTexture.materialProps,
																	name:data.backgroundTexture.name,
																	id:data.backgroundTexture.id} })
				}			

				if (data?.id) delete data.id
				if (data?.projectData?.projectId) delete data.projectData.projectId
				if (data?.projectData?.userId) delete data.projectData.userId
				updateProjectData({...data.projectData, ...{projectId:projectId,lighting:data.lighting,backgroundTexture:backgroundTexture,cameraPosition:data.cameraPosition,controlsTarget:data.controlsTarget}})            
			}
			else {
				updateProjectData({...data.projectData, ...{projectId:projectId}});
				resetCameraToOriginalPosition(controls)
			}
		}
	}

	const isTemplateDifferent = (template) => {
		const currentPhysicalObject = usePhysicalObjects.getState().physicalObjects
		if ( (!template) || (!currentPhysicalObject) ) return false
		return (JSON.stringify(template.physicalObjects) != JSON.stringify(currentPhysicalObject))

	}		
	const getName =(template)=>{
		return template.projectData.title
	}
    const getImageSrc = (template) => {
    	const url =  getUrlFromRef(template?.files?.screenshot);		
		return cloudflareCDN(url, 'width=400,format=auto');
	}

    if (sideBarMode != SideBarMode.Templates)   return (null)
	return (
		<Grid container spacing={1} style={{paddingTop:'17px',paddingLeft:'8px'}}>
			<Grid item xs={12} style={{paddingBottom:'5px',paddingTop:'18px',paddingRight:'15px'}} display='flex' justifyContent="center" >
			<ButtonGroup fullWidth={true} disableElevation variant="text"  >
				<TabButton className={templateType == "curated" ? 'selected' : ''} onClick ={ () => setTemplateType('curated') }>Curated</TabButton>
  				<TabButton className={templateType == "blank" ? 'selected' : ''} onClick ={ () => setTemplateType('blank') } >Blank</TabButton>
			</ButtonGroup>
			</Grid>
			<ScrollableGrid
			    style={{maxHeight:'84vh', overflow:'auto', paddingTop:'10px', paddingLeft:'4px'}}
			    getName={getName}
                getSrc={getImageSrc} 
				onClick={handleClick} 
				loadItems={()=>{}}
				items={templates}
				draggable={false}
                proTemplateIDs={proTemplateIDs}
				type="templates"
				/>
		</Grid>
    );
}