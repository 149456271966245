import React, {useContext, useState} from 'react'
import {Grid} from '@mui/material'
import WidgetsOutlinedIcon from '@mui/icons-material/WidgetsOutlined';
import ViewCompactOutlinedIcon from '@mui/icons-material/ViewCompactOutlined';
//import InsertPhotoOutlinedIcon from '@mui/icons-material/InsertPhotoOutlined';
import LocalFloristOutlinedIcon from '@mui/icons-material/LocalFloristOutlined';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import WallpaperIcon from '@mui/icons-material/Wallpaper';
import {SubheaderTypography,SidebarItemBox} from './TabStyles'
import {useMattoState} from '../MattoState'
import { SideBarMode } from '../ts/app_interfaces';
import FolderOpenRoundedIcon from '@mui/icons-material/FolderOpenRounded';
import { AuthContext } from "../auth/Auth";
import { useLoginState } from "../studioxr/auth/LoginState";
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import ColorLensIcon from '@mui/icons-material/ColorLens';
import TextFormatIcon from '@mui/icons-material/TextFormat';

const categories = [
					// {name:'Products', mode:SideBarMode.Products},
					{name:'Objects', mode:SideBarMode.Objects},
					// {name:'Products', mode:SideBarMode.Accents},
					{name:'Accessories', mode:SideBarMode.Accents},
					{name:'Paints', mode:SideBarMode.Paints},
					{name:'Collections', mode:SideBarMode.Collections},
					{name:'Templates', mode:SideBarMode.Templates},
					{name:'Labels', mode:SideBarMode.Text},
					// {name:'Environment', mode:SideBarMode.Environment},
					{name:'Images', mode:SideBarMode.Uploads},		
					{name:'My Materials', mode:SideBarMode.YourMaterials}	
					]
//					{name:'Background', mode:SideBarMode.Background},


const getCategoryIcon = (categoryName) => {
	switch (categoryName) {
		case 'Objects':  return <WidgetsOutlinedIcon/>;
		case 'Collections': return <FolderOpenRoundedIcon/>;
		// case 'Environment': return <InsertPhotoOutlinedIcon/>;
		// case 'Products': return <CategoryOutlinedIcon/>;
		case 'Templates': return <ViewCompactOutlinedIcon/>;
		case 'Background': return <WallpaperIcon/>;
		case 'Labels': return <TextFormatIcon/>;
		case 'Accessories': return <LocalFloristOutlinedIcon sx={{ fontSize: 26 }}/>;
		// case 'Products': return <ShoppingBagRoundedIcon />;
		case 'Images': return <CloudUploadOutlinedIcon/>;
		case 'My Materials': return <ImageOutlinedIcon sx={{fontSize:26}} />;
		case 'Paints': return <ColorLensIcon sx={{fontSize:26}}/>;
		default: return null;
	}
}

export const SideBar = (props) => {
	const sideBarMode = useMattoState((state) => state.sideBarMode)
    const setSideBarMode = useMattoState((state) => state.setSideBarMode);
	const setShowLogin = useLoginState((state) => state.setShowLogin);

	const { currentUser } = useContext(AuthContext);
    const handleClick = (sideBarMode) => { if(sideBarMode == SideBarMode.Collections && !currentUser?.uid) { setShowLogin('signup'); return; } else setSideBarMode(sideBarMode);};

	return (
		<Grid container spacing={1} >
			<Grid item xs={12} style={{paddingLeft:'8px', position:'relative'}}>
				{categories.map((category,index)=>(
					<SidebarItemBox key={index} onClick={() => handleClick(category.mode)} className={sideBarMode == category.mode ? 'selected':''}>
						{getCategoryIcon(category.name)}
						<SubheaderTypography>{category.name}</SubheaderTypography>
					</SidebarItemBox>
				))}
			</Grid>
	  </Grid>
	)
}
