import React, { useMemo,useRef, useState,useEffect } from 'react'
import { typesenseApiKey, typesenseHost } from '../libs/firebase';
import TypesenseInstantSearchAdapter from "typesense-instantsearch-adapter";
import { InstantSearch,Configure, InfiniteHits, useInfiniteHits } from 'react-instantsearch';
import { useMattoState } from '../MattoState';
import { ProductType } from '../../../../packages/ts-interfaces';
import { MaterialHits } from './MaterialHits';
import { randomInt } from 'crypto';
import { randomInteger } from '../libs/util';

interface TypesenseProps {
    indexName:string,
    productType:ProductType,
    hitsHandler:any,
    collectionArray?:string[],
    getProductsHitsFromTypesenseSearch?:any,
    collectionUserImages?:any,
    collectionUserMaterials?:any,
    appliedSorts?:string[],
    doQuery?:boolean,
    supplierID?:string
}

export const TypesenseSearch = ({indexName, productType, hitsHandler, collectionArray, collectionUserImages, collectionUserMaterials, getProductsHitsFromTypesenseSearch, appliedSorts,doQuery,supplierID}:TypesenseProps) => {
   if (!indexName || !hitsHandler) return (<div>not valid need indexName and hitsHandler! </div>)    
  
    const favoriteSelected = useMattoState((state) => state.favoriteSelected);
    const paintSearchCode = useMattoState((state) => state.paintSearchCode);

    const queryFilter = useMemo(() => {
      let q ='';
        switch (productType) {
            case ProductType.MATERIAL: q= 'objectStatus:APPROVED  &&  productType:=material'; break;
            case ProductType.NOT_STATIC: q= '(objectStatus:APPROVED || objectStatus:APPROVED_PRO) && productType:=not_static'; break;
            case ProductType.STATIC: q= '(objectStatus:APPROVED || objectStatus:APPROVED_PRO) && productType:=static'; break;
            case ProductType.PAINT: q= '  objectStatus:APPROVED && productType:=paint';break;
            case ProductType.PAINTOBJECT: q= 'objectStatus:APPROVED && productType:=paintObject';break;
            case ProductType.ANY: q= "objectStatus:APPROVED || objectStatus:APPROVED_PRO";break;      
            default: q= 'objectStatus:APPROVED  &&  productType:=material'           
        }
        if (supplierID) {
          q += ' && supplierID:'+ supplierID 
        }  

        if (favoriteSelected==true) {
          const userFavorites:any = useMattoState.getState().userFavoritesData
          if (favoriteSelected==true && productType==ProductType.MATERIAL && userFavorites?.constructor==Object && Object.keys(userFavorites).length >  0) {
            const materialIdArray = Object.keys(userFavorites?.materials).filter(k=> userFavorites.materials[k]==true).join(',')
            q += ' && id:[' + materialIdArray + ']'
          } 
        }
        if (collectionArray && collectionArray.length >= 0) {
            q += '&& id:[' + collectionArray + ']'
        }   
        if (productType==ProductType.PAINT && paintSearchCode) {
          q += ' && hue:>=' + (paintSearchCode[0] - 20) 
          q += ' && hue:<' + (paintSearchCode[0] + 20)
          q += ' && chroma:>=' + (paintSearchCode[1] - 20) 
          q += ' && chroma:<' + (paintSearchCode[1] + 20)
          q += ' && luminance:>=' + (paintSearchCode[2] - 20) 
          q += ' && luminance:<' + (paintSearchCode[2] + 20)   
        }       

        return q
    },[productType,favoriteSelected,paintSearchCode, collectionArray])
    const uiState = useMemo(() => {
      return  productType != ProductType.MATERIAL ?  {} :  { [indexName]: { page:randomInteger(1,20)  }}
    },[])

    //applied sorts might not be worht creating new instances for discover, etc..
    //const searchClient = useMemo(()=> createTypesenseSearchClient(appliedSorts),[appliedSorts])
    const searchClient = useMemo(()=> createTypesenseSearchClient(null),[])
    
    if (doQuery===false) return (null);

    if (!window.debug?.typesense_queries)  window.debug.typesense_queries=0
    window.debug.typesense_queries = window.debug?.typesense_queries + 1

    //if (productType != ProductType.MATERIAL) { return (null)}
    //HitHandler
    const HitHandler = hitsHandler;
    return (
        <InstantSearch   initialUiState={uiState} future={{preserveSharedStateOnUnmount:true}} indexName={indexName} searchClient={searchClient} >
          <Configure  filters={queryFilter}/>
          <HitHandler productType={productType}  getProductsHitsFromTypesenseSearch={getProductsHitsFromTypesenseSearch} collectionUserImages={collectionUserImages} collectionUserMaterials={collectionUserMaterials} />
        </InstantSearch>
      )
}



const createTypesenseSearchClient = (appliedSorts) => {
    const typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
        server: {
          apiKey:typesenseApiKey,
          nodes: [ { host: typesenseHost,port: 443, protocol: "https",} ],
          cacheSearchResultsForSeconds: 100 * 60,
        },
        additionalSearchParameters: { page:1,per_page:appliedSorts ? 40 :20, prefix:'true', query_by:'name, supplier, category', 
        sort_by: appliedSorts ? appliedSorts.join(", ") : 'rank:asc, updatedAt:desc' 
      }
      });      
      return typesenseInstantsearchAdapter.searchClient;
}

