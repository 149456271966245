import React from 'react'
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ReactTimeAgo from 'react-time-ago';
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import DeleteIconButton from '../buttons/DeleteIconButton';
import EditIconButton from '../buttons/EditIconButton';
import { cloudflareCDN } from './util';

const textCss = {textAlign:'center',textTransform:'capitalize', wordBreak: 'break-word', paddingLeft: '5px', paddingRight: '5px'}
const textTruncate = {overflow: 'hidden',textOverflow: 'ellipsis',display: '-webkit-box',WebkitLineClamp: '2', lineClamp: '2',WebkitBoxOrient: 'vertical'}

const imageListCss = { width: "7vw", height: "7vw", borderRadius:'13px', margin: '4px', cursor:'pointer',  marginTop: '17px', overflow: 'hidden'}



export const CollectionItemCover = (props) => {

	const ShowThumbnail = () => {
		if (props.coverImage?.length > 4) {
			return  (    
				<ImageList cols={1} sx={imageListCss} onClick={props.onClick} >
					<ImageListItem
						key={Math.random()}
						cols={1}
						rows={1}
					>
						<img srcSet={`${cloudflareCDN(props.coverImage,'height=100,format=auto') }?fit=crop&auto=format`}  />
					</ImageListItem>
				</ImageList> )
		}
		else return <QuiltedImageList images={props}/>
	}
	return (
		<Grid container spacing={0}  >
			<Grid item xs={12} style={{display:'flex', justifyContent:'center',position: 'relative'}} onMouseOver={props.onMouseOver} onMouseOut={props.onMouseOut}>
				<EditIconButton id={props.id} onClick={props?.editIconOnClick} />
				<DeleteIconButton id={props.id} onClick={props.deleteIconOnclick}/>				
				<ShowThumbnail />
		   </Grid>
		 	<Grid container item xs={12} justifyContent="center" >     		 
				<Typography variant="caption" style={{...textCss,...textTruncate, fontSize: '12px', fontWeight: 600, lineHeight: '13px', marginTop: '4px', marginBottom: '6px'}}>{props.name}</Typography> 
		 	</Grid>
			{props.type == 'collections' &&
				<Grid container item xs={12} >     		 
					<Grid item xs={6} justifyContent="left" >     		 
						<Typography variant="caption" style={{...textCss,...textTruncate, fontSize: '10px', fontWeight: 400, lineHeight: '11px'}}>{props.getNoOfItems()} items</Typography> 
					</Grid>
					<Grid item xs={6} justifyContent="right" > 
						<Typography variant="caption" style={{...textCss,...textTruncate, fontSize: '9px', fontWeight: 300, lineHeight: '10px', textAlign: 'right', paddingRight: '10px'}}><ReactTimeAgo date={props.lastUpdatedAt} locale="en-US" timeStyle="mini"/> </Typography> 
					</Grid>
				</Grid>
			}
		</Grid>
	)
}

function srcset(image, size, rows = 1, cols = 1) {

  // check if image is base64
  if (image.startsWith("data:image")) {
	return {
	  src: image,
	  srcSet: `${image} 2x`
	};
  }

  return {
    src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${size * cols}&h=${
      size * rows
    }&fit=crop&auto=format&dpr=2 2x`
  };
}

export default function QuiltedImageList(props) {
	let itemData;
	const isFavoritesCollection = false;//props.images.name == 'favorites'
	if(isFavoritesCollection){
		 itemData = [
			{
			  img: props.images.src1,
			  rows: 2,
			  cols: 3
			},
			{
			  img:props.images.src2,
			  rows: 2,
			},
			{
			  img: props.images.src3,
			  rows: 2,
			}
		  ];
	}
	else {
		 itemData = [
			{
			img: props.images.src1,
			rows: 2,
			cols: 3
			},
			{
			img:props.images.src2,
			cols: 2
			},
			{
			img: props.images.src3,
			cols: 2
			}
		];
	}
  return (
    <ImageList
      sx={imageListCss}
      variant="quilted"
      cols={5}
	  gap={1}
	  onClick={props.images.onClick}
    >
      {itemData.map((item,index) => (
        <ImageListItem
          key={Math.random()}
          cols={item.cols || 1}
          rows={item.rows || 1}
        >
          <img
            {...srcset(item.img, 121, item.rows, item.cols)}
            alt={item.title}
            loading="lazy"
			// style={isFavoritesCollection && index==0 ? {borderRadius:'0px 13px 13px 0px'}: isFavoritesCollection && index==1 ?{borderRadius:'0px 6px 6px 0px'}:{}}
          />
        </ImageListItem>
      ))}
    </ImageList>
  );
}