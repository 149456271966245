import React from 'react'
import { IconButton } from '@mui/material';
import { styled } from '@mui/system';
import { EditOutlined } from '@mui/icons-material';

const EditIconBtn = styled(IconButton)(() => ({
	height:'fit-content',
	position:'absolute',
	left:'0px',
	top:'0px',
	zIndex: 1
}))

export default function EditIconButton(props) {
	return (
		<EditIconBtn id={props.id +'editIcon'} onClick={props.onClick} style={{visibility:'hidden'}} >
			<EditOutlined  fontSize="small"/>
		</EditIconBtn>
    );
}


