import { ACESFilmicToneMapping,PCFShadowMap,SRGBColorSpace } from 'three';
import React,{ useRef, useState,useEffect,useLayoutEffect,useMemo,Suspense } from 'react'
import { Canvas, useFrame,useThree,useGraph,useLoader,extend } from '@react-three/fiber'
import { useGLTF,Environment,Plane,useProgress} from '@react-three/drei'
import {useMattoState,useProjectData} from '../MattoState'
import {PhysicalMaterial} from './PhysicalMaterial'
import useEventListener from '../libs/useEventListener';
import { colorTemperatureToRGB } from '../libs/util';
import { GridCM } from './objects/GridCM';
import studio from '../libs/studio.exr.js'



export const OriginalScene = (props) => {    
    const showGrid = useMattoState((state) => state.showGrid);    
    const backgroundTexture =  useProjectData( (state)=>state.backgroundTexture)

    return (
    <Canvas id='threejs_canvas' dpr={2} gl={{ outputColorSpace:SRGBColorSpace, toneMapping:ACESFilmicToneMapping, alpha: false,  antialias: true, preserveDrawingBuffer: false}}   
        shadows={{ type: PCFShadowMap }}  {...props}  camera={{ fov: 45,far:500 }} >
        <color args={ [ '#e5e5e7' ] } attach="background" />
        <Suspense fallback={null}><GridCM showGrid={showGrid} /></Suspense>
        {/* <gridHelper visible={showGrid} args={[770, 150, 'black', '#222222']} position={[0,0.1,0]}/> */}
        <mesh name={'floor'}  rotation={[ -Math.PI / 2, 0, 0]} receiveShadow={true} position={[0,0,0]}>
            <planeGeometry args={[200,200]} />
            <PhysicalMaterial material={backgroundTexture?.materialData ?? backgroundTexture} background={true} />
        </mesh>
        <LightingRig1 /> 
        {/* <SpotlighRig1 /> */}
        {props.children}
        <DisposeRendererOnUnmount />
    </Canvas>
    )
}

const DisposeRendererOnUnmount = () => {
    const { gl } = useThree();

    useEffect(() => {
        return () => {
            gl.dispose();
            console.log('Renderer disposed');
        };
    }, [gl]);

    return null;
};

export const LightingRig1 = (props) => {
    const [lm, setLM]=useState(false)
    useEventListener('keydown', (e) => { if (e.key=='L' ) { setLM(true) } }, document.body);
    const projectData = useProjectData()
    const post = useMattoState((state) => state.post);
	const { gl,invalidate } = useThree();
    const colorTmp = useMemo(() => {
        const t = projectData.lighting.colorTemperature || 7500
        return colorTemperatureToRGB(t)
    },[projectData.lighting.colorTemperature])

    useEffect(() => { gl.toneMappingExposure = projectData.lighting.exposure ?? 1; invalidate() }, [projectData.lighting.exposure])
    return (
        <>
            {/* <ambientLight intensity={projectData.lighting.ambient} name={"ambient light"} color='#fff' position={[0,300,0]} /> */}

            <directionalLight  name={"directional light"} 
            intensity={projectData.lighting.directionalIntensity || 0.5}
            position={[ projectData.lighting.directional || 41,100,0]} 
            castShadow={true} 
            color={colorTmp}
            shadow-mapSize-width={4096}
            shadow-mapSize-height={4096}
            shadow-camera-far={200}
            shadow-camera-near={10}
            shadow-camera-left={-50}
            shadow-camera-right={50}
            shadow-camera-top={50}
            shadow-camera-bottom={-50}
            shadow-bias={post.bias}
            shadow-normalBias={post.normalBias}
            shadow-radius={post.radius}
            shadow-blurSamples={post.samples}
            /> 
            {projectData.envmap && <Suspense fallback={null}>
                {/* <Environment files={ getUrlFromRef(getEnvironmentalMap(projectData.envmap).small_1k) } /> */}
                <Environment files={studio} />
            </Suspense>  }
        </>
    )
}
