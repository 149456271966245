import { IconButton, Modal, Box } from "@mui/material";
import { styled } from "@mui/material/styles";

export const PersonIconButton = styled(IconButton)(({ theme }) => ({
  margin: 0,
}));

export const ModalContainer = styled(Box)(({ theme }) => ({
  width: "77%",
  maxWidth: "500px",
  height: "calc(100vh - 100px)",
  margin: "auto",
  marginTop: "50px",
  background: "white",
  padding:"15px",
  borderRadius:'15px',

  display: "flex",

  ".inner-root": {
    flex: 1,
    overflow: "auto",
  },

  ".bg-img": {
    width: "22%",
    height: "100%",
    maxWidth: "216px",
    maxHeight: "calc(100vh - 100px)",
    objectFit: "cover",
  },
}));

export const SigninContainer = styled(Box)(({ theme }) => ({
  height: "100%",
  padding: "2px",
  position: "relative",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  boxSizing: "border-box",
  ".form-root": {
    maxWidth: "480px",
    margin: "auto",

    "&.signup": {
      paddingTop: "104px",
      position: "relative",
      boxSizing: "border-box",
      height: "100%",

      justifyContent: "center",
      flexDirection: "column",
    },
  },

  ".logo-img": {
    width: "130px",
    marginBottom: "10px",
  },

  ".login-title": {
    fontSize: "40px",
    fontFamily: "Gambarino",
    lineHeight: "115%",
    //color: theme.palette.customColor.main,
    marginBottom: "15px",
  },

  ".signup-link": {
    display: "flex",
    alignItems: "center",

    ".signup-text": {
      fontFamily: "Roundo-Regular",
      fontSize: "16px",
      fontWeight: "500",
      //color: theme.palette.customColor.secondary,
    },
    ".signup-btn": {
      fontFamily: "Roundo-Regular",
      fontSize: "16px",
      fontWeight: "500",
      marginLeft: "4px",
      //color: theme.palette.customColor.purple,
      background: "transparent",
      border: "none",
      cursor: "pointer",
      padding: 0,
      "&:hover": {
        textDecoration: "underline",
      },
    },
  },

  ".login-form": {
    marginTop: "10px",
  },

  ".forgot-root": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "38px",

    ".remember-check": {
      ".MuiTypography-root": {
        fontFamily: "Roundo-Regular",
        fontSize: "16px",
        fontWeight: "500",
        //color: theme.palette.customColor.main,
        marginTop: "2px",
      },
    },
  },

  ".google-btn": {
    marginTop: "15px",

    "> span": {
      marginTop: "0px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },

    svg: {
      width: "22px",
      height: "22px",
      marginBottom: "2px",
      marginRight: "8px",
    },
  },

  ".close-btn": {
    position: "absolute",
    right: "20px",
    top: "20px",
  },

  ".stepper-root": {
    width: "100%",
    marginTop: "40px",
    marginBottom: "40px",
    position: "absolute",
    top: 0,
    left: 0,

    ".MuiSvgIcon-root.Mui-active": {
      //color: theme.palette.customColor.purple,
    },

    ".MuiSvgIcon-root.Mui-completed": {
      //color: theme.palette.customColor.purple,
    },

    ".MuiStepLabel-label.Mui-active": {
      //color: theme.palette.customColor.secondary,
    },

    ".MuiStepLabel-label.Mui-completed": {
      //color: theme.palette.customColor.secondary,
    },
  },

  ".custom-select": {
    marginTop: "16px",
    marginBottom: "8px",
  },

  ".custom-helper-text": {
    fontFamily: 'Montserrat,Roboto,"Helvetica Neue",Nunito,Arial,sans-serif',
    color: "rgba(0, 0, 0, 0.6)",
    fontSize: "0.75rem",
    lineHeight: "1.66",
    textAlign: "left",
    marginTop: "3px",
    marginTight: "14px",
    marginTottom: "0",
    marginLeft: "14px",
  },

  ".success-second-text": {
    fontFamily: "Roundo-Regular",
    fontSize: "16px",
    fontWeight: "500",
    //color: theme.palette.customColor.secondary,
    marginTop: "-25px",
    marginBottom: "30px",
  },
}));
