import { Button, Divider, Modal, Stack, TextField, Typography } from "@mui/material"
import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { useMattoState } from "../../../MattoState";
import { CollectionInterface, CollectionUserImages, CollectionUserMaterials, validateCollection } from "ts-interfaces";
import { useFormik } from "formik";
import { encodeHTML, generateID } from "../../../libs/util";
import { AuthContext } from "../../../auth/Auth";
import { getDocByID, updateRecord } from "../../../libs/firebase";
import { produce } from "immer";
import { useLoginState } from "../../auth/LoginState";
import { ProfileUpload } from "../../../components/ProfileUpload";
import { ActionButton } from "../../../buttons/ButtonStyles";


const textFieldProps:any = { borderradius:'5px', variant: "outlined", margin: "normal", size: 'small', fullWidth: true, type: "text", sx: { paddingRight: '10px',width:'98%' }}

const style = {
    position: 'absolute' as const,
    top: '40%',left: '50%',
    transform: 'translate(-50%, -50%)',width: 400,
    bgcolor: 'background.paper',borderRadius: 2,
    boxShadow: 24,p: 4,
};

interface CollectionModalProps {
    name: string;
    id: string;
    product?: string;
    userImage?: CollectionUserImages;
    userMaterial?: CollectionUserMaterials;
}
export const CollectionModal = () => {
    const {currentUser} = useContext(AuthContext);
    const modal:CollectionModalProps | any = useMattoState((state) => state.modal);
    const isCollectionModal = modal?.name=='collection';
    if (!isCollectionModal || currentUser==null) return null;
    else { return <NewCollectionModal /> }
}
export const NewCollectionModal = () => {
    const {currentUser} = useContext(AuthContext);
    const modal:CollectionModalProps | any = useMattoState((state) => state.modal);
    const isCollectionModal = modal?.name=='collection';
    const isNewCollection = modal?.id==null;
	const setModal = useMattoState((state) => state.setModal);
    const handleClose = () => void setModal(null);
    const [saving,setSaving] = useState(false);
    const [collection,setCollection] = useState<CollectionInterface | null>();
    const [coverImage,setCoverImage] = useState<any>(null);
	const setSnackbarMessage = useMattoState((state) => state.setSnackbarMessage);
    const [errors, setErrors]= useState<any>({})
    const addOrUpdateCollection = useLoginState ((state) => state.addOrUpdateCollection)

    const formik:any = useFormik({
        initialValues: {
            name: collection?.name || '',
            description: collection?.description || '',
        },
        onSubmit: values => {  },
    });
    useEffect(() => {
        setSaving(false); setErrors({}); setSnackbarMessage(null);
        if (modal?.name=='collection' && modal?.id) {
            getDocByID('usersMyCollection',modal.id).then((doc:any) => {
                if (doc.exists) {
                    const collection:CollectionInterface = doc.data() as CollectionInterface
                    formik.values.name = collection.name;
                    formik.values.description = collection.description;
                    setCollection(doc.data() as CollectionInterface)
                }
            })
        }
        else if (currentUser) { setCollection(createNewCollection(currentUser?.uid)) }
        else { setCollection(null)}
    },[modal])  

    const vaildate = () => {
        const err:any = {} 
        if (!formik.values.name || formik?.values?.name?.length < 3)  err.name = 'Please enter a name for your collection'
        if (formik?.values?.description?.length > 300)  err.description = 'Description cannot be more than 300 characters.'
        if (Object.keys(err).length) { setErrors(err); return false; }
        else return true;
    }
    const handleSave = async () => {
        setErrors({})
        if (!vaildate()) return;
        setSaving(true);

        const newCollection:CollectionInterface = produce(collection, (draft:CollectionInterface) => {
            draft.name = encodeHTML(formik.values.name) || '';
            draft.description = encodeHTML(formik.values.description) || '';
            draft.updatedAt = Date.now();
            if (modal.product) {
                if (!draft.products)  draft.products = []
                if (!draft.products.includes(modal.product.id)) {
                    draft.products.push(modal.product.id)
                    if (!draft.thumbnails) draft.thumbnails = []
                    draft.thumbnails.push({id:modal.product.id,src:modal.product.src})
                }
            }        
            if (modal.userImage) {
                if (!draft.userImages) draft.userImages = []
                if (!draft.userImages.includes(modal.userImage)) {
                    draft.userImages.push(modal.userImage)
                    if (!draft.thumbnails) draft.thumbnails = []
                    draft.thumbnails.push({id:modal.userImage.id,src:modal.userImage.src})
                }
            }
            if(modal.userMaterial) {
                if (!draft.userMaterials) draft.userMaterials = []
                if (!draft.userMaterials.includes(modal.userMaterial)) {
                    draft.userMaterials.push(modal.userMaterial)
                    if (!draft.thumbnails) draft.thumbnails = []
                    draft.thumbnails.push({id:modal.userMaterial.id,src:modal.userMaterial.src})
                }
            }
        }) as CollectionInterface
        setCollection(newCollection);

        addOrUpdateCollection(newCollection, coverImage).then(() => {
            if (modal.product) useMattoState.getState().setSnackbarMessage(`${modal?.product?.name} updated in ${newCollection.name}`);            
            else if (modal.userImage) useMattoState.getState().setSnackbarMessage(`Image updated in ${newCollection.name}`);
            else if (modal.userMaterial) useMattoState.getState().setSnackbarMessage(`${modal?.userMaterial?.name} updated in ${newCollection.name}`);
            else useMattoState.getState().setSnackbarMessage(`Collection ${newCollection.name} saved`);
        })
        .catch(err =>  {
            console.log("Error Saving Collection!" + err)
            useMattoState.getState().setSnackbarMessage(`Error Saving Collection! ${err}`)
        })
        .finally( () => { setSaving(false); handleClose() })
    }
    const handleCoverImageUpload = (blob) => {if (blob) setCoverImage(blob)    }

    const handleShareCollection = () => {
        if (collection) {
            navigator.clipboard.writeText(`${window.location.origin}/project/new?collectionID=${collection.id}`)
            setSnackbarMessage(`Collection URL copied to clipboard`)
        }
    }

    if (!isCollectionModal || currentUser==null || collection==null) return null;

    return (        
        <Modal open={modal?.name=='collection'} onClose={handleClose} aria-labelledby="modal-collection">    
        <Stack sx={style}  >
            <Typography id="modal-modal-title" variant="h6" component="h2" style={{fontWeight:'800'}}>
                {isNewCollection ? 'Create New Collection' : 'Collection' }
            </Typography>
            <Stack alignItems='baseline'>
                <Button onClick={handleShareCollection} sx={{fontSize:'0.7em'}}>Share This Collection</Button>
            </Stack>
            <Stack direction='row' spacing={2}  justifyContent="space-between" >

              { (modal.userImage || modal.userMaterial || modal.product?.src)  &&   <img src={modal.userImage?.src || modal.userMaterial?.src || modal.product?.src}  style={{marginTop:'20px',maxHeight:'64px', borderRadius:'10px'}} />}
                <Stack style={{minWidth:'300px'}}  >
                    <TextField {...textFieldProps} id="name"  label="Collection Name" name="name" value={formik.values.name} onChange={formik.handleChange} />
                    {errors.name && <Typography variant="caption" color="red">Collection name must be more than 3 characters</Typography> }                
                    <TextField {...textFieldProps} id="description"  label="Collection Description" name="description" multiline rows={2} value={formik.values.description} onChange={formik.handleChange} />
                    {errors.description && <Typography variant="caption" color="red">Description must be less than 300 characters</Typography> }

                    <ProfileUpload disabled={saving} maxResizeWidth={500} onFileChange={handleCoverImageUpload} img={collection.coverImage} title='Cover Image' defaultText=' ' />
                    <Divider style={{paddingTop:'20px'}} />

                    <Stack style={{paddingTop:'20px'}} spacing={2} direction={'row'} justifyContent="space-between">
                        <ActionButton variant="contained" onClick={()=> handleClose()} disabled={saving} >Cancel</ActionButton>
                        <ActionButton variant="contained" onClick={handleSave} disabled={saving} >{saving ? 'Saving...' : 'Save Collection'}</ActionButton>
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
        </Modal>
    )
}

export const createNewCollection = (currentUserUid) => {
    if (!currentUserUid) throw new Error("currentUserUid is required")
    const data:CollectionInterface = {
        id: generateID(),
        name: '',
        uid: currentUserUid,
        updatedAt: Date.now(),
        createdAt: Date.now(),
        protectedCollection: false,
        products: [],
        description: "",
        coverImage: "",
        userImages: [],
        userMaterials: [],
        thumbnails:[]
    };
    return data;
}
