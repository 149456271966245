import React, { useState, useEffect, useContext, useRef } from 'react'
import { Grid, ButtonGroup } from '@mui/material'
import { deleteRecordAndFiles, userImagesDB, getPublicURL, MAX_FREE_UPLOADS} from '../libs/firebase';
import { query, where, getDocs, orderBy, limit,startAfter } from "firebase/firestore";
import { AuthContext } from "../auth/Auth";
import { useMattoState } from '../MattoState'
import { resizeImage, uploadImage, checkFileType, cloudflareCDN } from '../libs/util'
import { ScrollableGrid } from './ScrollableGrid';
import { getCollections, useLoginState } from "../studioxr/auth/LoginState";
import { SideBarMode } from '../ts/app_interfaces';
import { CollectionInterface, UserProfileRole } from 'ts-interfaces';
import { TabButton } from '../buttons/ButtonStyles.jsx';
import { ActionButton } from '../buttons/ButtonStyles';
import { ProfileInterface } from 'ts-interfaces';
import { curatedImagesUserId } from '../libs/firebase.js';
import { CollectionList } from './CollectionTab.js';

enum ImageType { MY = 'my', CURATED = 'curated' }
const getNoOfItems = (coll:CollectionInterface) =>   coll.products.length; 

export function UploadsTab() {
	const fileRef = useRef<HTMLInputElement>(null);
	const {currentUser} = useContext(AuthContext);
	const setShowLogin = useLoginState((state) => state.setShowLogin);
    const sideBarMode = useMattoState((state) => state.sideBarMode);
	const [uploads, setUploads]=useState([])
    const [imageType, setImageType]=useState<ImageType>(ImageType.MY) 
	const [uploadsLoaded, setuploadsLoaded]=useState(false)
	const [isLoading,setIsLoading]=useState(false)
	const isUploading = useMattoState((state)=>state.isUploading)
    const [selectedImageFolder, setSelectedImageFolder]=useState<any | null>(null)
    const [curatedImagesLoaded, setCuratedImagesLoaded]=useState(false)
    const [curatedImages, setCuratedImages]=useState<CollectionInterface[]>([])

	const loadItems = () =>{
		if (isLoading==false && uploadsLoaded==false) {
			loadUploads(false)
		}
	}

	const loadCuratedImageCollections = () => {
		if (imageType == ImageType.MY) return loadItems();
		else if (curatedImagesLoaded==false) {
			getCollections(curatedImagesUserId, curatedImages, 20).then((newCuratedCollections) => {
				const mergedCuratedImages = [...curatedImages, ...newCuratedCollections]
				if (newCuratedCollections.length < 20) setCuratedImagesLoaded(true) 
				setCuratedImages(mergedCuratedImages as CollectionInterface[])
			});
		}
	}

	useEffect(() => { 
        if (imageType == ImageType.MY && uploads.length == 0) loadItems()
        else if (imageType==ImageType.CURATED && curatedImages.length == 0) loadCuratedImageCollections()
    },[imageType])


	const loadUploads = (clearUploads=true) => {
		if(!currentUser) return;
		if (isLoading==true) return;
		if (sideBarMode!=SideBarMode.Uploads) return;
		setIsLoading(true)
		const a = [userImagesDB, where("uid", "==", currentUser.uid), orderBy("updatedAt","desc"),limit(20)]
		if (uploads.length > 0) {
			a.splice(a.length-1, 0, startAfter(uploads[uploads.length-1].updatedAt))
		}
		const q = query.apply(this, a)
		getDocs(q).then ( querySnapshot => {
			setuploadsLoaded(querySnapshot.size == 0)
			const prevUploads = clearUploads ? [] : uploads
			setIsLoading(false)			
			const imagesTemp = []
			querySnapshot.forEach((doc) => {imagesTemp.push(doc.data()) })
			const newUploadsValue = [...prevUploads, ...imagesTemp]
			if(newUploadsValue.length > 0)
			setUploads(newUploadsValue)
		})
		.catch((error) => { console.log("Error getting userImages: ", error); });
	}

	useEffect(() => { 
		if (sideBarMode==SideBarMode.Uploads) loadUploads(false) 
		return () => { }
	},[sideBarMode])
	
	useEffect(() => {  if(!isUploading) { setUploads([]); } },[isUploading])

	useEffect(() => {
		if(uploads.length === 0) loadUploads(false);
	}, [uploads])
	
	const handleMouseOver = (e,id) =>  { { 
		e.preventDefault(); 
		const element = document.getElementById(id + 'delIcon');
		if (element) {
			element.style.visibility = 'visible';
		}
	}}
	const handleMouseOut = (e,id) =>  { { 
		e.preventDefault();  
		const element = document.getElementById(id + 'delIcon');
		if (element) {
			element.style.visibility = 'hidden';
		}
	}}

	const deleteIconOnclick = (e,upload) => {
		if (window.confirm('Are you sure you wish to delete this upload?')){	
		e.stopPropagation()
		deleteRecordAndFiles(upload.id,"userImages").then(() => {
		    const newUploads = uploads.filter(u => u != upload)
		    setUploads(newUploads)
		})
	}
		else { e.stopPropagation() }
	}

	const onDrag = (e,upload) => { 
		const src = upload.files.file;
		let dynamicMaterialProps;

		if(upload.files.bg_removed_file) {
			const bg_removed_file = upload.files.bg_removed_file;
			dynamicMaterialProps = {
				files: bg_removed_file, 
				backgroundRemoved: true,
				backgroundRemovedFile: bg_removed_file,
				originalFile: src,
				repeat:{x:null, y:null}, offset:{x:null, y:null},
				metadata:{name:upload?.name || 'Upload', uid:upload?.uid}
			};
		} else {
			dynamicMaterialProps = {files:src, 
				repeat:{x:null, y:null}, offset:{x:null, y:null},
				metadata:{name:upload?.name || 'Upload', uid:upload?.uid}};
		}
		e.dataTransfer.setData("upload",JSON.stringify(dynamicMaterialProps))
	}

	const checkIfUserCanUpload = () => {		
		const profile: ProfileInterface | any= useLoginState.getState().profile
		if (!profile?.uid)  {  setShowLogin('signup'); return false }
		if (uploads?.length >= MAX_FREE_UPLOADS && profile.userRole!=UserProfileRole.PRO) {
			window?.gtag?.('event','max_uploads_reached')
			useMattoState.getState().setShowSubscription(['startTrial','You have reached the maximum number of uploads allowed. To continue uploading upgrade to a PRO account.']); 
			return  false
		}
		return true
	}

	const uploadMediaClick = (e) => {
		e.preventDefault()
		if (!currentUser?.uid) { setShowLogin('signup'); return; }
		if (checkIfUserCanUpload() === false ) return;
		else{ 
			if(fileRef.current) {
				fileRef.current.value = null; 
				fileRef.current.click()
			}
		}
	};

	function handleFileSelect(evt) {
		evt.preventDefault()
		evt.stopPropagation()

		const files = evt.target.files;
		const newUploads = [...uploads]
		uploadImageFile(files,newUploads)
	}
    const uploadImageFile = (files,newUploads) => {
		for (let i = 0;i < files.length; i++) {
			const file = files[i]

			const fileType = checkFileType(file)
			const isImage = fileType.isImage;
			const toPath = fileType.toPath; 
			const fileName = fileType.fileName;

			if (isImage) {
			    resizeImage(file,1000,700,fileName).then( (imgCanvas: any) => {
					useMattoState.setState({ denyUpload:false})
					if (currentUser) {
						useMattoState.setState({ isUploading:true })
						uploadImage(imgCanvas.id,imgCanvas,toPath,currentUser.uid).then(result =>{
							useMattoState.setState({ isUploading:false })
							 newUploads = [result,...newUploads]
						     setUploads(newUploads)
						})
						.catch(()=>{
							useMattoState.setState({ isUploading:false })
						})
					}
					else
					{
						useMattoState.setState({ denyUpload:true})
					}										  
				})
			}
		}
	}
	
	const trimUploadName =(upload)=>{
		const name = upload.name
		return name.split('.').slice(0, -1).join('.')
	}

	const getImageSrc = (upload) => {
		const url = upload?.files?.file
    	const storageUrl = getPublicURL(url);
		return cloudflareCDN(storageUrl, 'height=100,format=auto') 
	}
	const getImageFiles = (upload) => {
		return upload?.files 
	}

	const handleCloseSelected = () => setSelectedImageFolder(null);

	const handleClickCuratedCollection = (collection) => {
		setSelectedImageFolder(collection)
	}

	if(selectedImageFolder) return <CollectionList selectedCollection={selectedImageFolder} onClose={handleCloseSelected} />

	return (
		<Grid container spacing={1} style={{paddingTop:'17px',paddingLeft:'8px',display:'flex'}} >
			<Grid item xs={12} style={{paddingBottom:'5px',paddingTop:'18px',paddingRight:'15px'}} display='flex' justifyContent="center" >
				<ButtonGroup fullWidth={true} disableElevation variant="text"  >
					<TabButton className={imageType == ImageType.CURATED ? 'selected' : ''} onClick ={ () => { setSelectedImageFolder(null); setImageType(ImageType.CURATED) }}>Curated</TabButton>
					<TabButton className={imageType == ImageType.MY ? 'selected' : ''} onClick ={ () => { setSelectedImageFolder(null); setImageType(ImageType.MY) }}>My Images</TabButton>
				</ButtonGroup>
			</Grid>
			{imageType == ImageType.MY &&
				<>
					<Grid item xs={12}>
						<input ref={fileRef} accept=".webp,.jpg,.png" style={{display:'none'}} id="file-input" type="file" multiple onChange={(e)=>handleFileSelect(e)} />
						<ActionButton onClick={(e) => uploadMediaClick(e)}>Upload Image</ActionButton>
					</Grid>
				
					<ScrollableGrid
						id="scrollableDivUploads"
						style={{maxHeight:'85vh', overflow:'auto', paddingTop:'10px', paddingLeft:'4px'}}
						getName={trimUploadName}
						getSrc={getImageSrc} 
						draggable={true} 
						onDragStart={onDrag}
						deleteIconOnclick={deleteIconOnclick} 
						onMouseOver={handleMouseOver} 
						onMouseOut={handleMouseOut} 
						loadItems={loadItems}
						items={uploads}
						type='uploads'
						currentUserUid={currentUser?.uid} 
						getImageFiles={getImageFiles} 
					/>	
				</>
			}
			{imageType == ImageType.CURATED &&
				<ScrollableGrid
					style={{maxHeight:'85vh', overflow:'auto', paddingTop:'10px', paddingLeft:'4px'}}
					loadItems={loadCuratedImageCollections}
					onClick={handleClickCuratedCollection}
					getNoOfItems={getNoOfItems}
					items={curatedImages}
					draggable={false}
					type='curatedImages'/>
			}

		</Grid>
    );
}