

export const SupplierLogos = [
    {name: '3-form', url: 'https://storage.googleapis.com/mattoboard-b8284.appspot.com/suppliers/AVZ99FARYd/3-form.png'},
    {name: '41Zero42', url: 'https://storage.googleapis.com/mattoboard-b8284.appspot.com/suppliers/FnhslNTrBt/41Zero42.png'},
    {name: 'Acupanel', url: 'https://storage.googleapis.com/mattoboard-b8284.appspot.com/suppliers/I2gRyFe8ZI/Acupanel.png'}, 
    {name: 'Alfa wood', url: 'https://storage.googleapis.com/mattoboard-b8284.appspot.com/suppliers/70QM0ks0Hx/Alfa wood.png'},
    {name:'Anthology Woods', url:'https://storage.googleapis.com/mattoboard-b8284.appspot.com/suppliers/r57rgmu4k4/1701831919610_logo'},
    {name: 'Anthropologie', url: 'https://storage.googleapis.com/mattoboard-b8284.appspot.com/suppliers/mFnAcl5poA/Anthropologie.png'}, 
    {name:'Artistry Hardwood Flooring', url:'https://storage.googleapis.com/mattoboard-b8284.appspot.com/suppliers/ezKPKBOJ7CV3W9zqnxy5/1703855329860_logo'},
    {name: 'Astek', url: 'https://storage.googleapis.com/mattoboard-b8284.appspot.com/suppliers/rYUoq0pdmj/Astek_updated.png'},
    {name: 'Balineum', url: 'https://storage.googleapis.com/mattoboard-b8284.appspot.com/suppliers/3oji7D4aZW/Balineum.png'}, 
    {name:'BEHR', url:'https://storage.googleapis.com/mattoboard-b8284.appspot.com/suppliers/behr/1701786884393_logo'},
    {name: 'Buster + Punch', url: 'https://storage.googleapis.com/mattoboard-b8284.appspot.com/suppliers/jshHaf87W9/BusterPunch.png'}, 
    {name: 'Caesar Ceramics', url: 'https://storage.googleapis.com/mattoboard-b8284.appspot.com/suppliers/YwYTVIwU0h/Caesar Ceramics.png'}, 
    {name: 'Camengo', url: 'https://storage.googleapis.com/mattoboard-b8284.appspot.com/suppliers/Vt0MoqKgFA/Camengo.png'}, 
    {name: 'Carnegie', url: 'https://storage.googleapis.com/mattoboard-b8284.appspot.com/suppliers/U7G4awfeLP/Carnegie2.png'}, 
    {name: 'Claybrook', url: 'https://storage.googleapis.com/mattoboard-b8284.appspot.com/suppliers/SJmDRr6vCT/Claybrook.png'}, 
    {name: 'Cle Tile', url: 'https://storage.googleapis.com/mattoboard-b8284.appspot.com/suppliers/9Rfr80r6MM/Cle_updated.png'}, 
    {name: 'Concrete Collaborative', url: 'https://storage.googleapis.com/mattoboard-b8284.appspot.com/suppliers/CMO6MqFmAz/Concrete Collaborative.png'}, 
    {name:'Cortina Leathers', url:'https://storage.googleapis.com/mattoboard-b8284.appspot.com/suppliers/WKZWD6gg9bmohAjbWLhl/1701951884295_logo_transparent.png'},
    {name: 'Cosentino', url: 'https://storage.googleapis.com/mattoboard-b8284.appspot.com/suppliers/tTV8xdwbtf/Cosentino_updated.png'}, 
    {name: 'Dedar', url: 'https://storage.googleapis.com/mattoboard-b8284.appspot.com/suppliers/E7W0EsLBA9/Dedar.png'}, 
    {name: 'Domus', url: 'https://storage.googleapis.com/mattoboard-b8284.appspot.com/suppliers/D93UpDZllS/Domus2.png'}, 
    {name: 'Durat', url: 'https://storage.googleapis.com/mattoboard-b8284.appspot.com/suppliers/ngQZhsIrOS/Durat.png'}, 
    {name: 'Ege Carpets', url: 'https://storage.googleapis.com/mattoboard-b8284.appspot.com/suppliers/JzdKA96Ksw/Ege Carpets.png'}, 
    {name: 'Egger', url: 'https://storage.googleapis.com/mattoboard-b8284.appspot.com/suppliers/kIAl8VVmwO/Egger.png'}, 
    {name: 'Elitis', url: 'https://storage.googleapis.com/mattoboard-b8284.appspot.com/suppliers/WWAUc7P3aW/Elitis.png'}, 
    {name:'Fabric Bank', url:'https://storage.googleapis.com/mattoboard-b8284.appspot.com/suppliers/Sfd9nWGRe1Nh2FNYPA8M/1701787224571_logo_transparent.png'},
    {name: 'Fioranese', url: 'https://storage.googleapis.com/mattoboard-b8284.appspot.com/suppliers/KzrzAnYMea/Fioranese.png'}, 
    {name: 'FibreGuard', url: 'https://storage.googleapis.com/mattoboard-b8284.appspot.com/suppliers/fibreguard/FibreGuard.png'}, 
    {name:'Foglizzo', url:'https://storage.googleapis.com/mattoboard-b8284.appspot.com/suppliers/xf4feD10j6OjQINvf1ix/1701787265980_logo_transparent.png'},
    {name:'Foresso', url:'https://storage.googleapis.com/mattoboard-b8284.appspot.com/suppliers/h9lmrv57fs/1701790285029_logo'},
    {name: 'Forms + Surfaces', url: 'https://storage.googleapis.com/mattoboard-b8284.appspot.com/suppliers/yvP2FO3OHS/FormsSurfaces.png'}, 
    {name: 'FR-One', url: 'https://storage.googleapis.com/mattoboard-b8284.appspot.com/suppliers/frOne/FROne.png'}, 
    {name: 'hansgrohe', url: 'https://storage.googleapis.com/mattoboard-b8284.appspot.com/suppliers/E4kUlH5lmp/Hansgrohe_updated.png'}, 
    {name: 'Ikea', url: 'https://storage.googleapis.com/mattoboard-b8284.appspot.com/suppliers/qYOYYgL0sS/Ikea.png'}, 
    {name: 'Inox Scheiftechnik', url: 'https://storage.googleapis.com/mattoboard-b8284.appspot.com/suppliers/IFFCEKRJVZ/Inox_updated.png'}, 
    {name: 'Joseph Giles', url: 'https://storage.googleapis.com/mattoboard-b8284.appspot.com/suppliers/g21mmfZbp0/Joseph Giles.png'}, 
    {name:'Jung', url:'https://storage.googleapis.com/mattoboard-b8284.appspot.com/suppliers/je455mvydnvMlHgvS7kW/Jung_updated.png'},
    {name:'Jeffrey Court', url:'https://storage.googleapis.com/mattoboard-b8284.appspot.com/suppliers/SYOEV5b6S2y_pkHl0AEyYA/1707215809654_logo'},
    {name: 'Kelly Wearstler', url: 'https://storage.googleapis.com/mattoboard-b8284.appspot.com/suppliers/yk7WTbEFAi/Kelly Wearstler.png'}, 
    {name: 'Kerakoll', url: 'https://storage.googleapis.com/mattoboard-b8284.appspot.com/suppliers/3XAO4bYr9i/Kerakoll2.png'}, 
    {name: 'Kvadrat', url: 'https://storage.googleapis.com/mattoboard-b8284.appspot.com/suppliers/1yK90vsBRP/Kvadrat.png'}, 
    {name: 'Laminex', url: 'https://storage.googleapis.com/mattoboard-b8284.appspot.com/suppliers/TaEi1fz5po/Laminex.png'},
    {name:'Laurent Badier', url:'https://storage.googleapis.com/mattoboard-b8284.appspot.com/suppliers/r1bjuah4zr/1703090706277_logo_transparent.png'},
    {name: 'Lebatex', url: 'https://storage.googleapis.com/mattoboard-b8284.appspot.com/suppliers/SNnXR0fpFf/Lebatex.png'}, 
    {name: 'Lewis & Wood', url: 'https://storage.googleapis.com/mattoboard-b8284.appspot.com/suppliers/4qXi8wwv7D/LewisWood.png'}, 
    {name: 'Lust Home', url: 'https://storage.googleapis.com/mattoboard-b8284.appspot.com/suppliers/mEAHJMuMEp/LustHome_updated.png'}, 
    {name: 'Maharam', url: 'https://storage.googleapis.com/mattoboard-b8284.appspot.com/suppliers/5dgm9somus/Maharam.png'}, 
    {name: 'MalthaDesign', url: 'https://storage.googleapis.com/mattoboard-b8284.appspot.com/suppliers/84iesnjvQk/MalthaDesign.png'}, 
    {name: 'Meraki', url: 'https://storage.googleapis.com/mattoboard-b8284.appspot.com/suppliers/rAszrQYCmK/Meraki.png'}, 
    {name: 'Mutina', url: 'https://storage.googleapis.com/mattoboard-b8284.appspot.com/suppliers/uk9oeBbvU9/Mutina.png'}, 
    {name: 'Nais', url: 'https://storage.googleapis.com/mattoboard-b8284.appspot.com/suppliers/I9NGivwP6O/Nais_updated.png'}, 
    {name:'Nasco', url:'https://storage.googleapis.com/mattoboard-b8284.appspot.com/suppliers/WP0W1GeyVGD37v3eBZYL/1704723982293_logo'},
    {name: 'Naturtex', url: 'https://storage.googleapis.com/mattoboard-b8284.appspot.com/suppliers/z3KMik2P10/Naturtex.png'}, 
    {name: 'Object Carpet', url: 'https://storage.googleapis.com/mattoboard-b8284.appspot.com/suppliers/bfOyEOvTQt/Object Carpet.png'}, 
    {name:'Opposite Wall', url:'https://storage.googleapis.com/mattoboard-b8284.appspot.com/suppliers/djnrl4dm0f/1701790393193_logo_transparent.png'},
    {name:'Organoid', url:'https://storage.googleapis.com/mattoboard-b8284.appspot.com/suppliers/TF8tnCL7hKzjZE9e9Sio/1706871820414_logo_transparent.png'},
    {name: 'Otto Tiles', url: 'https://storage.googleapis.com/mattoboard-b8284.appspot.com/suppliers/pUAKMWjoTH/Otto Tiles.png'}, 
    {name: 'Pollack', url: 'https://storage.googleapis.com/mattoboard-b8284.appspot.com/suppliers/BiPQxdMewW/Pollack.png'}, 
    {name: 'Porcelanosa', url: 'https://storage.googleapis.com/mattoboard-b8284.appspot.com/suppliers/MEVlgCANgL/Porcelanosa.png'}, 
    {name: 'Portobello', url: 'https://storage.googleapis.com/mattoboard-b8284.appspot.com/suppliers/7Iq5dt0LFR/Portobello.png'}, 
    {name:'Pushka Home', url:'https://storage.googleapis.com/mattoboard-b8284.appspot.com/suppliers/e2jZmLJLYPnZbS6lrHiw/1704480605403_logo'},
    {name: 'Quintessenza Ceramiche', url: 'https://storage.googleapis.com/mattoboard-b8284.appspot.com/suppliers/x7GopXAVqL/Quintessenza Ceramiche.png'}, 
    {name:'Rous', url:'https://storage.googleapis.com/mattoboard-b8284.appspot.com/suppliers/7qWfR1Z1G2S1tL1twv79/1701790420702_logo'},
    {name: 'Sanderson', url: 'https://storage.googleapis.com/mattoboard-b8284.appspot.com/suppliers/0LoufrEGtH/Sanderson.png'}, 
    {name: 'Signorino', url: 'https://storage.googleapis.com/mattoboard-b8284.appspot.com/suppliers/5hjyd7e8k6/signorino.png'}, 
    {name:'Snowsound', url:'https://storage.googleapis.com/mattoboard-b8284.appspot.com/suppliers/uCma8QbvffnUg5myIWBS/1701952528530_logo'},
    {name: 'Solid Nature', url: 'https://storage.googleapis.com/mattoboard-b8284.appspot.com/suppliers/3Fl8lmJjCM/SolidNature_updated.png'}, 
    {name: 'Solus', url: 'https://storage.googleapis.com/mattoboard-b8284.appspot.com/suppliers/CsmtD8XUAf/Solus.png'}, 
    {name: 'Theia', url: 'https://storage.googleapis.com/mattoboard-b8284.appspot.com/suppliers/DpNsOTk8MY/Theia.png'}, 
    {name: 'Tile Bar', url: 'https://storage.googleapis.com/mattoboard-b8284.appspot.com/suppliers/NJoMtweAR9/Tile Bar.png'}, 
    {name: 'Vescom', url: 'https://storage.googleapis.com/mattoboard-b8284.appspot.com/suppliers/VMFSIDRFJx/Vescom.png'}, 
    {name: 'Victoria Larson', url: 'https://storage.googleapis.com/mattoboard-b8284.appspot.com/suppliers/oedvyduoSm/VictoriaLarson_updated.png'}, 
    {name: 'Wall & Deco', url: 'https://storage.googleapis.com/mattoboard-b8284.appspot.com/suppliers/WRBJMwznMA/WallDeco.png'}, 
    {name:'Wall Art', url:'https://storage.googleapis.com/mattoboard-b8284.appspot.com/suppliers/2kjzk43bwd/1703092005124_logo_transparent.png'},
    {name: 'Waxed Floors', url: 'https://storage.googleapis.com/mattoboard-b8284.appspot.com/suppliers/q86OnMaADF/Waxed Floors.png'}
]

